import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const agendaApi = createApi({
  reducerPath: 'agendaApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAgendas: builder.query({
      query: ({ startTime, endTime }) =>
        `/api/legislate/agendas?startTime=${startTime}&endTime=${endTime}`,
    }),
    getActiveAgenda: builder.query({
      query: (uid) => `legislate/api/agendas/${uid}`,
    }),
    getAgendasFromUids: builder.mutation({
      query: (uids) => ({
        url: '/api/legislate/agendas/from-uids',
        method: 'POST',
        body: uids,
      })
    })
  }),
});

export const { useGetAgendasQuery, useGetActiveAgendaQuery, useGetAgendasFromUidsMutation} = agendaApi;
