import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errorMessage: null
};

const error = createSlice({
  name: 'error',
  initialState,
  reducers: {
    displayError: (state, {payload}) => {
      state.errorMessage = payload;
    }
  }
});

export const { displayError } = error.actions;

export default error.reducer;