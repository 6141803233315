import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from '@trussworks/react-uswds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import RefreshButton from './RefreshButton';
import { Button } from '@trussworks/react-uswds';
import { CvMeetingLiveIcon } from "../../../../assets/images/legislate/imageIndex";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
  EventStateEnum,
  NoEncoderMemaEventStatusEnum,
} from '../../../constants/eventConstants';
import {
  MeetingStatus,
  AvailabilityStatus,
  VotecastAudience,
  ErrorNotificationMessage
} from '../../../constants/cloudVotingConstants';
import { displayErrorNotification } from '../../common/liveMeetingSlice';
import {
  getAgendaList,
  getAgendaListByMeetingGuid,
  getVotecastCustomerId,
  getMembersByCustomerIdDisplayName,
} from '../../../../amplify/graphql/queries';
import { deleteMembers, updateMemberAvailabilityStatus } from '../../../../amplify/graphql/mutations';
import { useGetCurrentUserQuery /*, useGetCurrentPersonUidQuery */ } from '../../services/authApi';
import { setCustomerId, setUsername, setPersonUid } from '../Auth/authSlice';
import { invokeGraphqlOperation } from "../../../helpers/votecastWebHelpers/graphqlOperations";
import { useGetAgendasFromUidsMutation } from "../../services/agendaApi";
import { processChunks } from "../../../helpers/votecastWebHelpers/openCloseMeetingActions";

export default function IndexTable(props) {
  const {
    dataType = '',
    captionText = '',
    emptyDataMsg = '',
    tableHeadings = [],
    tableData = [],
    dataStatus = '',
    handleClickRow,
    reload,
    loadedDataTime,
    cloudVotingEnabled,
  } = props;

  const dispatch = useDispatch();
  const [meetingOpenToJoin, setMeetingOpenForJoin] = useState([]);
  let customerId = useSelector(state => state.auth.customer_uid);
  const userName = useSelector(state => state.auth.username);
  const personUid = useSelector(state => state.auth.personUid);
  const authParameter = { customerId: customerId, audience: VotecastAudience.LEGISLATE };
  const [getAgendasFromUids, { isLoading: isGetAgendasFromUidsLoading }] = useGetAgendasFromUidsMutation();

  const {
    data: currentUserData,
    isSuccess: isCurrentUserSuccess,
    isError: isCurrentUserError,
    error: currentUserError,
  } = useGetCurrentUserQuery(undefined, {
    skip: !cloudVotingEnabled,
  });

  useEffect(() => {
    if (!currentUserError) return;
    // eslint-disable-next-line no-console
    console.error('Error loading current user data: ', currentUserError);
    // eslint-disable-next-line max-len
    // Todo: Need to add error handling that will alert user that CV is not available. Here we just swallow the error and display the table as if CV is not enabled.
  }, [isCurrentUserError, currentUserError])

  useEffect(() => {
    if (cloudVotingEnabled && isCurrentUserSuccess && currentUserData) {
      dispatch(setCustomerId(currentUserData?.customer_uid));
      dispatch(setUsername(currentUserData?.user?.table?.fullname));
    }
  }, [cloudVotingEnabled, isCurrentUserSuccess, currentUserData, dispatch])

  // Commenting out fetching and setting of current person UID based on user name
  // const currentUserName = currentUserData ? currentUserData?.user?.table?.fullname : '';
  // const { data: currentPersonUidData, isSuccess: isCurrentPersonUidSuccess } = useGetCurrentPersonUidQuery(currentUserName, {
  //   skip: !cloudVotingEnabled || !currentUserName,
  // });
  // useEffect(() => {
  //   if (cloudVotingEnabled && isCurrentPersonUidSuccess ) {
  //     dispatch(setPersonUid(currentPersonUidData[0]?.uuid));
  //   }
  // }, [isCurrentPersonUidSuccess])

  useEffect(() => {
    if (cloudVotingEnabled && customerId) {
      meetingsOpenToJoinForAttendee();
    }
    // Cleanup function
    return () => {
      setMeetingOpenForJoin([]);
    };
  }, [cloudVotingEnabled, customerId]);

  const handleKeyPress = (e, id) => {
    e.preventDefault();
    if (e.key === 'Enter') {
      handleClickRow(id);
    }
  };

  const meetingsOpenToJoinForAttendee = async() => {
    const customerDomain = window.location.host;
    if (customerDomain !== 'mm.lvh.me') {
      const customerData = await invokeGraphqlOperation(getVotecastCustomerId, { domain: customerDomain }, authParameter);
      if (customerData && customerData.uid) {
        customerId = customerData.uid;
      }
    }

    const meetings = await invokeGraphqlOperation(getAgendaList, { customer_uid: customerId }, authParameter);
    if (meetings && meetings.length > 0) {
      let openMeetings = meetings.filter(m => [MeetingStatus.OPEN, MeetingStatus.RUNNING, MeetingStatus.PAUSED].includes(m.meeting_status));

      // Get the list of meetings for which the logged in user is attendee
      let meetingForAttendee = await invokeGraphqlOperation(
        getMembersByCustomerIdDisplayName,
        {
          customer_uid: customerId,
          person_uid: personUid,
          display_name: userName,
        },
        authParameter
      );

      const meetingGuidToAgendaUidMapping = [];
      /**
       * stoppedMeetings is the list of meetings which are stopped or stoppedNoEncoder 
       * AND staleData which was not deleted due to autostop of the vent in MeMA when hypatia limaweb clerk tab is inactive
       */
      let stoppedMeetings = [];
      let meetingGuidsToCheck = [];
      if(meetingForAttendee?.length > 0){
        // check if exists in agenda table -> if yes then get meeting_guid else it is stale data
        for (const member of meetingForAttendee) {
          // if(member.availability_status === AvailabilityStatus.JOINED){
            const agendaRecordForJoinedMeeting = meetings.find(m => m.uid === member.agenda_uid);
            if (agendaRecordForJoinedMeeting) {
              meetingGuidToAgendaUidMapping.push({member_uid: member.uid, meeting_guid: agendaRecordForJoinedMeeting.meeting_guid, agenda_uid: agendaRecordForJoinedMeeting.uid, in_agenda_table: true, availability_status: member.availability_status});
              meetingGuidsToCheck.push(agendaRecordForJoinedMeeting?.meeting_guid);
            } else {
              meetingGuidToAgendaUidMapping.push({member_uid: member.uid, meeting_guid: '', agenda_uid: member.agenda_uid, in_agenda_table: true, availability_status: member.availability_status});
              // It is a case of stale data
              stoppedMeetings.push({agenda_uid: member.agenda_uid, member_uid: member.uid});
            }
          // } 
        }
      }
      let eventDetails = [];
      if(meetingGuidsToCheck?.length > 0){
        try {
          const eventDetailsResponse = await getAgendasFromUids(meetingGuidsToCheck).unwrap();
          eventDetails = eventDetailsResponse?.events;
        } catch (err) {
         console.log(err);
        }
      }
      if(eventDetails.length > 0){
        for (const eventDetail of eventDetails) {
          const meeting = meetingGuidToAgendaUidMapping.find(m => m.meeting_guid === eventDetail.uid);
          if (meeting) {
            if (eventDetail.status === 'Stopped' || eventDetail.status === 'StoppedNoEncoder') {
              stoppedMeetings.push({agenda_uid: meeting.agenda_uid, member_uid: meeting.member_uid});
            }
          }
        }
      }

      // console.log('stoppedMeetings', stoppedMeetings);

      // Delete Stopped Meeting Members From DynammoDB
      if(stoppedMeetings.length > 0){
        const stoppedMeetingMembersToDelete = stoppedMeetings.map(stale => {
          stale.member_uid
          const result = { 
            uid: stale.member_uid,
            customer_uid: customerId,
          };
          return result;
        });

        let deletedMembers = [];
        if (stoppedMeetingMembersToDelete && stoppedMeetingMembersToDelete.length > 0) {
          deletedMembers = await processChunks(Object.values(stoppedMeetingMembersToDelete), deleteMembers, authParameter);
        }
      }
      

      if (stoppedMeetings.length > 0) {
        meetingForAttendee = meetingForAttendee.filter(
          member => !stoppedMeetings.some(stale => stale.agenda_uid === member.agenda_uid)
        );

        openMeetings = openMeetings.filter(
          meeting => !stoppedMeetings.some(stale => stale.agenda_uid === meeting.uid)
        );
      }

      const openMeetingIdList = openMeetings.map(m => m.uid);
      if (openMeetings.length > 0 && meetingForAttendee.length > 0) {
        for (const member of meetingForAttendee) {
          if (openMeetingIdList.includes(member.agenda_uid)) {
            setMeetingOpenForJoin(prevState => [...prevState,
              {
                meetingId: openMeetings.find(m=>m.uid === member.agenda_uid).meeting_guid,
                agendaUid:  openMeetings.find(m=>m.uid === member.agenda_uid).uid,
                memberId: member.uid,
                availabilityStatus: member.availability_status,
                votecastAgendaUid: member.agenda_uid
              }
            ]);
          }
        }
      }
    }
  };

  const renderDynamicHeadings = () => {
    return tableHeadings.map((heading, index) => {
      return (
        <th
          key={heading}
          data-testid={index + heading}
          className={'index-table-headings'}
        >
          {heading}
        </th>
      );
    });
  };

  const updateMemberJoinedInput = (meetingId) => ({
    customer_uid: customerId,
    uid: meetingOpenToJoin.find(meeting => meeting.votecastAgendaUid === meetingId)?.memberId,
    availability_status: AvailabilityStatus.JOINED
  });

  const handleJoinMeetingClick = async(id, uid) => {
    const meetingData = await invokeGraphqlOperation(getAgendaListByMeetingGuid, { customer_uid: customerId, meeting_guid: uid }, authParameter);

    if (!meetingData || meetingData.length === 0) {
      dispatch(displayErrorNotification(ErrorNotificationMessage.JOIN_MEETING_ERROR));
      return;
    }

    const filteredAndSortedMeetingData = meetingData
      ?.filter(item => [MeetingStatus.OPEN, MeetingStatus.RUNNING, MeetingStatus.PAUSED].includes(item.meeting_status))
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    if (filteredAndSortedMeetingData?.length > 0) {
      const liveVotecastAgendaUid = filteredAndSortedMeetingData[0]?.uid;
      const updateMemberData = await invokeGraphqlOperation(updateMemberAvailabilityStatus,
        { input: updateMemberJoinedInput(liveVotecastAgendaUid) }, authParameter);

      if (updateMemberData && updateMemberData.availability_status === AvailabilityStatus.JOINED) {
        setMeetingOpenForJoin(prevState => prevState.map(meeting =>
          meeting.votecastAgendaUid === uid ? { ...meeting, availabilityStatus: AvailabilityStatus.JOINED } : meeting
        ));
      }
      handleClickRow(id);
    }
    else {
      //show Join error message
      dispatch(displayErrorNotification(ErrorNotificationMessage.JOIN_MEETING_ERROR));
    }
  };

  const isMeetingOpenToJoin = (meetingId) => {
    const joinedMeeting = meetingOpenToJoin.find(meeting => meeting.availabilityStatus === AvailabilityStatus.JOINED);
    return joinedMeeting
      ? joinedMeeting.meetingId === meetingId
      : meetingOpenToJoin.some(detail => detail.meetingId === meetingId);
  };

  const isJoined = (meetingId) => {
    const meetingDetail = meetingOpenToJoin.find(detail => detail.meetingId === meetingId);
    return meetingDetail ? meetingDetail.availabilityStatus === AvailabilityStatus.JOINED : false
  };

  const renderLiveIcon = (isEventRunning) => {
    if (cloudVotingEnabled && !isEventRunning) {
      return null;
    }

    return <img className="padding-left-1"
      src={CvMeetingLiveIcon}
      alt='Live Meeting'
      id='agenda-live-meeting'
    />;
  };

  /*
  Make sure the props you are passing for headings and
  the data for the rows are in the same order that you want them to be displayed in,
  with ID as the last object property in the data (UID is not displayed in the table, but is used for onClick actions)
   */
  const renderDynamicCells = (rowData) => {
    return Object.keys(rowData).map((key) => {
      // Do not display the ID, UID, eventLevelCVFlag in the table row
      if (key === 'uid' || key === 'id' || key === 'status' || key === 'cloudVotingEnableForEvent') return null;
      const isEventRunning =
        rowData.status === EventStateEnum.Running ||
        rowData.status === NoEncoderMemaEventStatusEnum.RunningNoEncoder ||
        rowData.status === EventStateEnum.Paused ||
        rowData.status === EventStateEnum.Live;

      const isMeetingNameCol = key === 'name';
      return (
        <td key={key} title={rowData[key]}>
          {isMeetingNameCol && cloudVotingEnabled && rowData?.cloudVotingEnableForEvent ? (
            <>
              {rowData[key]}
              {renderLiveIcon(isEventRunning)}
            </>
          ) : (
            rowData[key]
          )}
        </td>
      );
    });
  };

  const renderRow = (rowData, index) => {
    return (
      <tr
        key={index}
        tabIndex={index + 1}
        onClick={(e) => { e.stopPropagation(); handleClickRow(rowData.id); }}
        onKeyPress={(e) => { e.stopPropagation(); handleKeyPress(e, rowData.id) }}
        className={'index-table-row'}
        data-testid={index}
      >
        {renderDynamicCells(rowData)}
        <td>
          <a
            onClick={() => handleClickRow(rowData.id)}
            onKeyPress={(e) => handleKeyPress(e, rowData.id)}
          >
            { 
              cloudVotingEnabled ? 
              (
                !rowData?.cloudVotingEnableForEvent ? (
                  <Button className="btn-blue-background" disabled={true}> Join Meeting</Button>
                ) : (
                  (
                    isMeetingOpenToJoin(rowData.uid)
                  ) ? (
                    isJoined(rowData.uid) ? (
                      <>
                        <FontAwesomeIcon
                          icon={faCheckCircle} size={'lg'}
                          style={{ marginRight: 8.5,color: 'green' }}
                        />
                        <span>Joined</span>
                      </>
                    ) : (
                      <Button className="btn-blue-background" onClick={(e) => {e.stopPropagation(); handleJoinMeetingClick(rowData.id, rowData.uid);}}> Join Meeting</Button>
                    )
                  ) : (
                    <Button className="btn-blue-background" disabled={true}> Join Meeting</Button>
                  )
                )
              ) : (
                <FontAwesomeIcon
                  icon={'angle-right'}
                  className={'icon-navigation'}
                />
              )
            }
          </a>
        </td>
      </tr>
    );
  };

  const renderTableBody = () => {
    if (dataStatus === 'loaded') {
      return tableData.map((rowData, index) => {
        return renderRow(rowData, index);
      });
    } else if (dataStatus === 'loading' || dataStatus === 'initializing') {
      return (
        <tr className={'index-table-non-linked-row'}>
          <td colSpan='3'>Loading...</td>
        </tr>
      );
    } else {
      return (
        <tr className={'index-table-non-linked-row'}>
          <td colSpan='3'>We encountered a problem while loading {dataType}</td>
        </tr>
      );
    }
  };

  const renderRefreshButton = () => {
    const isLoadingData =
      dataStatus === 'loading' || dataStatus === 'initializing';
    return dataStatus !== 'initializing' ? (
      <RefreshButton
        text={loadedDataTime && `Updated ${loadedDataTime}`}
        onClick={reload}
        isLoading={isLoadingData}
      />
    ) : <div></div>;
  };

  const renderTableCaption = () => {
    if (dataStatus === 'loaded') {
      return tableData.length === 0 ? (
        <div>
          <strong>{emptyDataMsg}</strong>
        </div>
      ) : (
        <div>{captionText}</div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <>
      <div className="index-table-header">
        {renderTableCaption()}
        {renderRefreshButton()}
      </div>
      <Table fullWidth={true}>
        <thead>
          <tr
            className={'index-table-heading-row'}
            data-testid={'index-table-heading-row'}
          >
            {renderDynamicHeadings()}
            <th className={'index-table-action-column'}>View Details</th>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </Table>
    </>
  );
}

IndexTable.displayName = 'Index Table';

IndexTable.propTypes = {
  dataType: PropTypes.string.isRequired,
  captionText: PropTypes.string.isRequired,
  emptyDataMsg: PropTypes.string.isRequired,
  tableHeadings: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  dataStatus: PropTypes.string.isRequired,
  handleClickRow: PropTypes.func.isRequired,
  reload: PropTypes.func,
  loadedDataTime: PropTypes.string,
  cloudVotingEnabled: PropTypes.bool,
};

IndexTable.defaultProps = {
  reload: () => {},
  loadedDataTime: '',
  cloudVotingEnabled: false,
};
