import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const agendaApi = createApi({
  reducerPath: 'agendaApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAgenda: builder.query({
      query: (event_uid) => `vcd/api/agenda/${event_uid}`,
    }),
  }),
});

export const { useGetAgendaQuery } = agendaApi;
