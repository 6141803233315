import GraphQLAPI, { graphqlOperation } from '@aws-amplify/api';
import axios from 'axios';
import { store as LimawebStore  } from '../../components/new_limaweb/store';
import LegislateStore from '../../legislate/app/store';
import VCDStore from '../../vcd/app/store';
import { displayError as displayErrorLimaweb } from '../../components/new_limaweb/reducers/error';
import { displayErrorNotification } from '../../legislate/common/liveMeetingSlice';
import { displayError as displayErrorVcd } from '../../vcd/common/errorSlice';
import { VotecastAudience, ErrorNotificationMessage } from '../../constants/cloudVotingConstants';

export const LIMAWEB_JWT_TOKEN_URL = '/services/minutes/limaweb/api/votecastweb/auth/token';
export const LEGISLATE_JWT_TOKEN_URL = '/legislate/api/votecastweb/auth/token';
export const VCD_JWT_TOKEN_URL = '/vcd/api/votecastweb/auth/token';

export const getJWTToken = async (customer_uid, audience) => {
    try {
        let apiUrl;
        if (audience === VotecastAudience.LIMA_WEB) {
            apiUrl = LIMAWEB_JWT_TOKEN_URL;
        } else if (audience === VotecastAudience.LEGISLATE) {
            apiUrl = LEGISLATE_JWT_TOKEN_URL;
        } else if (audience === VotecastAudience.VCD) {
            apiUrl = VCD_JWT_TOKEN_URL;
        } else {
            console.error('Unknown audience: ', audience);
        }
        const response = await axios.post(apiUrl, {
            votecastweb_customer_uid: customer_uid
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching JWT token:', error);
    }
};

export const displayErrorMessage = (error, audience) => {
    if (audience === VotecastAudience.LIMA_WEB) {
        LimawebStore.dispatch(displayErrorLimaweb({ message: error }));
    } else if (audience === VotecastAudience.LEGISLATE) {
        LegislateStore.dispatch(displayErrorNotification(error));
    } else if (audience === VotecastAudience.VCD) {
        VCDStore.dispatch(displayErrorVcd(error));
    } else {
        console.error('Unknown audience: ', audience);
    }
}

export const invokeGraphqlOperationWithNextToken = async (query, variables, authParameter) => {
    let allData = [];
    let nextToken = null;
    try {
        const authToken = await getJWTToken(authParameter?.customerId, authParameter.audience);
        do {
            const response = await GraphQLAPI.graphql(graphqlOperation(query, { ...variables, nextToken }, authToken));
            const key = Object.keys(response.data)[0];
            const data = response?.data?.[key];
            const error = response?.data?.error?.errors?.[0]?.toLowerCase() || '';
            if (error) {
                handleError(query, error, authParameter?.audience);
            }
            allData = [...allData, ...data.items];
            nextToken = data.nextToken;
        } while (nextToken);

        return allData;
    } catch (error) {
        handleError(query, error?.errors?.[0], authParameter?.audience);
    }
}

const handleError = (query, error, audience) => {
    const isMutation = query.trim().startsWith('mutation');

    // Extract the operation name
    const operationNameMatch = query.match(/(mutation|query)\s+(\w+)/);
    const operationName = operationNameMatch ? operationNameMatch[2] : 'unknown';
    if (isMutation) {
        console.error(`Mutation error (${operationName}):`, error);
        displayErrorMessage(ErrorNotificationMessage.MUTATION_ERROR_NOTIFICATION, audience);
        throw new Error(`Mutation "${operationName}" failed: ${error?.message}`);
    } else {
        console.error(`Query error (${operationName}):`, error);
        displayErrorMessage(ErrorNotificationMessage.QUERY_ERROR_NOTIFICATION, audience);
        throw new Error(`Query "${operationName}" failed: ${error?.message}`);
    }
}

export const invokeGraphqlOperation = async (query, variables, authParameter) => {
    try {
        const authToken = await getJWTToken(authParameter?.customerId, authParameter.audience);
        const response = await GraphQLAPI.graphql(graphqlOperation(query, variables, authToken));
        const key = Object.keys(response.data)[0];
        const data = response?.data?.[key];
        const error = response?.data?.error?.errors?.[0]?.toLowerCase() || '';
        if (error) {
            handleError(query, error, authParameter?.audience);
        }
        return data;
    } catch (error) {
        handleError(query, error?.errors?.[0], authParameter?.audience);
    }
}