import { createSlice } from "@reduxjs/toolkit";

// Other keys could be added here, see legistate/components/Auth/authSlice.js
const initialState = {
  isLoggedIn: isLoggedIn(),
};

// If needed, add reducers, see legistate/components/Auth/authSlice.js
const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
  },
});

function isLoggedIn() {
  return getGatCookie() ? true : false;
}

function getGatCookie() {
  const gat = document.cookie
    .split("; ")
    .find((row) => row.startsWith("_gat="));

  if (gat) return gat.split("=")[1];
}

export const selectAuth = (state) => state.auth;

// If needed, add actions, see legistate/components/Auth/authSlice.js
// export const { } = auth.actions;

export default auth.reducer;
