import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducers';

// https://redux.js.org/usage/configuring-your-store
// https://redux.js.org/usage/writing-tests
// Note: default middleware included by default
// Only need getDefaultMiddleware() when adding
// custom middleware and still wanting the defaults:
// middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat
// (actionLogger) where actionLogger is a middleware not in the defaults

// const actionLogger = () => (next) => (action) => {
//   console.log('Dispatching action:', action);
//   return next(action);
// };

// Custom middleware to collect dispatched actions
const createActionCollectorMiddleware = (actions) => () => (next) => (action) => {
  actions.push(action);
  return next(action);
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([]),
  devTools: { trace: true, traceLimit: 25 },
});

// Using this function to create the store allows
// for easier testing when a store is needed
// This function will be used to setup the store for testing only
export const setupStore = (preloadedState, actions = []) => {
  const actionCollectorMiddleware = createActionCollectorMiddleware(actions);

  const store =  configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(actionCollectorMiddleware),
    devTools: { trace: true, traceLimit: 25 },
  });

  store.getActions = () => actions;

  return store;
};
