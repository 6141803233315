import { baseQuery } from "./baseQuery";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
export const customerApi = createApi({
    reducerPath: 'customerApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
      getCustomer: builder.query({
        query: () => '/api/setting/name'
      })
    })
  });
  
  export const { useGetCustomerQuery } = customerApi;