import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetActiveAgendaQuery, useGetAgendasFromUidsMutation } from "../../services/agendaApi";
import { useGetActiveAgendaBookmarksQuery } from "../../services/bookmarkApi";
import { useGetActiveAgendaNotesQuery } from "../../services/noteApi";
import { useGetCurrentUserQuery /*, useGetCurrentPersonUidQuery */ } from '../../services/authApi';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  setActiveAgenda,
  setItemKeys,
  setItemsDictionary,
  setSelectedItemKey,
  resetData
} from "./activeAgendaSlice";
import { Card, CardGroup, Button } from "@trussworks/react-uswds";
import AgendaItems from "./AgendaItems";
import { buildAgendaData, validatePacketUrl } from "../../helpers/agendaHelper";
import { getDateInOffsetTime } from "../../helpers/dayjsHelpers";
import settings from "../../common/settings";
import Breadcrumb from "../Navigation/Breadcrumb";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { selectIsCloudVotingEnabled, setSettingValueForEvent } from "../../common/settingSlice";
import { setCustomerId, setUsername } from '../Auth/authSlice';
import JoinMeetingButton from "../Common/JoinMeetingButton";
import LeaveMeetingButton from "../Common/LeaveMeeting";
import { CvMeetingLiveIcon } from "../../../../assets/images/legislate/imageIndex";
import classNames from "classnames";
import {
  setLiveMeeting,
  resetData as resetLiveMeetingData,
  setMemberDetails,
  displayErrorNotification
} from "../../common/liveMeetingSlice";
import { setCalledSpeaker } from '../../common/liveMeetingSpeakerSlice';
import { MeetingStatus, AvailabilityStatus, MemberAttendance, VotecastAudience, ErrorNotificationMessage } from "../../../constants/cloudVotingConstants";
import { getMembersByCustomerIdDisplayName, getAgendaListByMeetingGuid, getAgendaList } from '../../../../amplify/graphql/queries';
import { deleteMembers, updateMemberAvailabilityStatus } from '../../../../amplify/graphql/mutations';
import ErrorNotification from '../Common/ErrorNotification';

import Container from "./ItemDetail/Container";
import { invokeGraphqlOperation } from '../../../helpers/votecastWebHelpers/graphqlOperations';
import { processChunks } from "../../../helpers/votecastWebHelpers/openCloseMeetingActions";

const ActiveAgenda = () => {
  const dispatch = useDispatch();
  const { agenda_uid: agendaUid, item_uid: itemUid } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isItemSelected, setIsItemSelected] = useState(false);
  const [treeData, setTreeData] = useState(undefined);

  const [isMeetingOpenOrRunning, setIsMeetingOpenOrRunning] = useState(false);
  const [isMeetingRunning, setIsMeetingRunning] = useState(false);
  const [memberId, setMemberId] = useState('');
  const [isMeetingJoined, setMeetingJoined] = useState(false);

  const personUid = useSelector(state => state.auth.personUid);
  const customerId = useSelector(state => state.auth.customer_uid);
  const userName = useSelector(state => state.auth.username);

  const [getAgendasFromUids, { isLoading: isGetAgendasFromUidsLoading }] = useGetAgendasFromUidsMutation();

  const {
    data: agenda,
    isSuccess: isAgendaSuccess,
    isError: isAgendaError,
  } = useGetActiveAgendaQuery(agendaUid);

  useEffect(() => {
    if (agenda) {
      dispatch(setSettingValueForEvent({ 
        CloudVotingEnableForEvent: agenda?.enable_voting ? true : false,
        ShowIndividualVoteForEvent: agenda?.is_live_voting ? true : false
      }));
    }
  }, [agenda?.id]);

  const {
    data: bookmarks,
    isSuccess: isBookmarksSuccess,
    isError: isBookmarksError,
  } = useGetActiveAgendaBookmarksQuery(agendaUid);

  const votecast_enableErrorNotification = useSelector(state => state.liveMeeting.errorMessage);

  const {
    data: notes,
    isSuccess: isNotesSuccess,
    isError: isNotesError,
  } = useGetActiveAgendaNotesQuery(agendaUid);

  const isCloudVotingEnabled = useSelector(selectIsCloudVotingEnabled);
  const {
    data: currentUserData,
    isSuccess: isCurrentUserSuccess,
    isError: isCurrentUserError,
  } = useGetCurrentUserQuery(undefined, { skip: !isCloudVotingEnabled });

  useEffect(() => {
    if (isCloudVotingEnabled && isCurrentUserSuccess) {
      dispatch(setCustomerId(currentUserData?.customer_uid));
      dispatch(setUsername(currentUserData?.user?.table?.fullname));
    }
  }, [isCloudVotingEnabled, isCurrentUserSuccess])


  /* Commenting out fetching and setting of current person UID based on user name
  const currentUserName = currentUserData ? currentUserData.user?.table?.fullname : '';
  const {
    data: currentPersonUidData,
    isSuccess: isCurrentPersonUidSuccess,
    isError: isCurrentPersonUidError,
  } = useGetCurrentPersonUidQuery(currentUserName, {
    skip: !isCloudVotingEnabled || !currentUserName,
  });
  useEffect(() => {
    if (cloudVotingEnabled && isCurrentPersonUidSuccess ) {
      dispatch(setPersonUid(currentPersonUidData[0]?.uuid));
    }
  }, [isCloudVotingEnabled, isCurrentPersonUidSuccess])*/

  const authParameter = { customerId: customerId, audience: VotecastAudience.LEGISLATE };

  useEffect(() => {
    let isCancelled = false;
    const fetchMeetingData = async () => {
      if (isCloudVotingEnabled && customerId && agendaUid && userName) {
        const meetingData = await invokeGraphqlOperation(getAgendaListByMeetingGuid, { customer_uid: customerId, meeting_guid: agendaUid }, authParameter);
        const filteredAndSortedMeetingData = meetingData
          ?.filter(item => [MeetingStatus.OPEN, MeetingStatus.RUNNING, MeetingStatus.PAUSED].includes(item.meeting_status))
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        const liveVotecastAgendaUid = filteredAndSortedMeetingData?.[0]?.uid ?? null;
        const liveVotecastMeetingStatus = filteredAndSortedMeetingData?.[0]?.meeting_status ?? null;
        dispatch(setLiveMeeting({ meetingGuid: agendaUid, votecastAgendaUid: liveVotecastAgendaUid, meetingStatus: liveVotecastMeetingStatus }));

        if (filteredAndSortedMeetingData?.length > 0) {
          if ([MeetingStatus.RUNNING, MeetingStatus.PAUSED].includes(filteredAndSortedMeetingData[0].meeting_status)) {
            setIsMeetingRunning(true);
          } else {
            setIsMeetingRunning(false);
          }
          
          /**
           * stoppedMeetings is the list of meetings which are stopped or stoppedNoEncoder 
           * AND staleData which was not deleted due to autostop of the vent in MeMA when hypatia limaweb clerk tab is inactive
           */
          let stoppedMeetings = [];
          // Get the list of meeting for which the logged in user is attendee
          const meetingForAttendee = await invokeGraphqlOperation(getMembersByCustomerIdDisplayName, {
            customer_uid: customerId, person_uid: personUid, display_name: userName}, authParameter);
          if (meetingForAttendee?.length > 0) {
            // GET Meeting guid from agenda uid this needs a graphql call
            const meetings = await invokeGraphqlOperation(getAgendaList, { customer_uid: customerId }, authParameter);
            let meetingGuidsToCheck = [];
            let meetingGuidToAgendaUidMapping = [];
            meetingForAttendee.forEach(e => {
              const meeting = meetings.find(m => m.uid === e.agenda_uid);
              if (meeting) {
                meetingGuidToAgendaUidMapping.push({meeting_guid: meeting.meeting_guid, agenda_uid: e.agenda_uid, member_uid: e.uid});
                meetingGuidsToCheck.push(meeting.meeting_guid);
              } else {
                meetingGuidToAgendaUidMapping.push({meeting_guid: '', agenda_uid: e.agenda_uid, member_uid: e.uid});
                stoppedMeetings.push({
                  agenda_uid: e.agenda_uid,
                  member_uid: e.uid,
                  meeting_guid: ''
                });
              }
            });
            // console.log('meetingGuidsToCheck', meetingGuidsToCheck);
            // Find the status of all JOINED meetings
            let eventDetails = [];
            if(meetingGuidsToCheck.length > 0){
              try {
                const eventDetailsResponse = await getAgendasFromUids(meetingGuidsToCheck).unwrap();
                eventDetails = eventDetailsResponse?.events;
                // console.log('eventDetails', eventDetails);
              } catch (err) {
                console.log(err);
              }
              if(eventDetails.length > 0){
                for (const eventDetail of eventDetails) {
                  const meeting = meetingGuidToAgendaUidMapping.find(m => m.meeting_guid === eventDetail.uid);
                  if (meeting) {
                    if (eventDetail.status === 'Stopped' || eventDetail.status === 'StoppedNoEncoder') {
                      stoppedMeetings.push({
                        agenda_uid: meeting.agenda_uid, 
                        member_uid: meeting.member_uid,
                        meeting_guid: eventDetail.uid
                      });
                    }
                  }
                }
              }
            }
            // console.log('stoppedMeetings', stoppedMeetings);
            // Delete Stale Members From DynammoDB
            if(stoppedMeetings.length > 0){
              const stoppedMeetingMembersToDelete = stoppedMeetings.map(stale => {
                stale.member_uid
                const result = { 
                  uid: stale.member_uid,
                  customer_uid: customerId,
                };
                return result;
              });

              let deletedMembers = [];
              if (stoppedMeetingMembersToDelete && stoppedMeetingMembersToDelete.length > 0) {
                deletedMembers = await processChunks(Object.values(stoppedMeetingMembersToDelete), deleteMembers, authParameter);
              }
            }
          }
          if (meetingForAttendee && meetingForAttendee.length > 0) {
            const otherJoinedMeetings  = meetingForAttendee.filter(meeting => meeting.availability_status === AvailabilityStatus.JOINED 
              && meeting.agenda_uid !== liveVotecastAgendaUid);
            // console.log('otherJoinedMeetings', otherJoinedMeetings);
            // If any other legit meeting is JOINED then don't allow to join the current meeting
            if (otherJoinedMeetings?.length > 0) {
              const isAnyOtherLegitMeetingJoined = otherJoinedMeetings.filter(
                joinedMeeting => !stoppedMeetings.some(staleRecord => staleRecord.agenda_uid === joinedMeeting.agenda_uid)
              );
                if (isAnyOtherLegitMeetingJoined?.length > 0) {
                setIsMeetingOpenOrRunning(false);
                return;
                }
            }
            // If current meeting is stale then don't allow to join the current meeting
            if(stoppedMeetings.some(staleRecord => staleRecord.agenda_uid === liveVotecastAgendaUid)){
              setIsMeetingOpenOrRunning(false);
              return;
            }
            if (!isCancelled) {
              setIsMeetingOpenOrRunning(true);
            }
            const memberData = meetingForAttendee.filter(meeting => meeting.agenda_uid === liveVotecastAgendaUid);
            if (memberData && memberData.length > 0) {
              for (const member of memberData) {
                if (member.person_uid === personUid || member.display_name === userName) {
                  if (!isCancelled) {
                    setMemberId(member.uid);
                    setMeetingJoined(member.availability_status === AvailabilityStatus.JOINED);
                    dispatch(
                      setMemberDetails({
                        memberId: member.uid,
                        memberJoinStatus: member.availability_status === AvailabilityStatus.JOINED,
                        isAttendeePresent: member.status && member.status.toLowerCase() === MemberAttendance.PRESENT,
                        isChair: member.chair,
                        isVoter: member.voting,
                      }));
                  }
                  break;
                }
              }
            }
          }
        } else {
          setIsMeetingOpenOrRunning(false);
          setIsMeetingRunning(false);
        }
      }
    };
    fetchMeetingData();
    return () => {
      isCancelled = true;
    };
  }, [isCloudVotingEnabled, customerId, agendaUid, personUid, userName]);

  const testOnLeaveMeeting = () => {
    dispatch(resetLiveMeetingData());
  };

  // CV TEMP TO BE CHANGED - END

  useEffect(() => {
    if (isAgendaSuccess && isBookmarksSuccess && isNotesSuccess) {
      dispatch(
        setActiveAgenda([
          agenda,
          notes.councilNotesByItemUid,
          bookmarks.bookmarkedItemUids,
        ])
      );

      // combine & build tree data
      const [treeData, itemsDict, keys] = buildAgendaData(
        agenda,
        notes.councilNotesByItemUid,
        bookmarks.bookmarkedItemUids
      );

      setTreeData(treeData);

      dispatch(setItemKeys(keys));

      // build agenda items dictionary to improve the performance while navigating items (next/prev)
      dispatch(setItemsDictionary(itemsDict));

      if (!isItemSelected) {
        setIsItemSelected(true);
        let currentItem;

        if (itemUid) {
          currentItem = itemUid; //if params has itemUid
        }/* else if (
          meetingGuid === agendaUid && !!currentItemGuid
        ) {
          currentItem = currentItemGuid; //focus to current live Item - realtime
        }*/ else {
          const firstItemKey = keys.length > 0 ? keys[0] : null;
          if (firstItemKey) {
            currentItem = firstItemKey;
          }
        }

        dispatch(setSelectedItemKey(currentItem));
      }
      setIsLoading(false);
    }
  }, [
    agendaUid,
    isAgendaSuccess,
    isBookmarksSuccess,
    isNotesSuccess,
    bookmarks?.bookmarkedItemUids.length,
    notes?.councilNotesByItemUid,
  ]);
 
  useEffect(() => {
    if (itemUid) {
      dispatch(setSelectedItemKey(itemUid));
    }
  }, [dispatch, itemUid]);

  useEffect(() => { }, [agenda?.name]);

  useEffect(() => {
    // Will fetch the real userGuid from graphql
    // dispatch(setPersonUid("b1b5fc14-a58a-40b0-a93f-5010d9e7874c")); // CV TEMP TO BE CHANGED

    // returned function will be called on component unmount
    return () => {
      dispatch(resetData());
      dispatch(resetLiveMeetingData());
    };
  }, []);

  const Page = ({ children }) => {
    return (
      <>
        {(isCloudVotingEnabled && votecast_enableErrorNotification) ? <ErrorNotification /> : ""}
        <div className={votecast_enableErrorNotification ? 'agenda-error-Notification' : ''}>
          <Breadcrumb />
        </div>
        {children}
      </>
    );
  };

  const renderTitle = () => {
    const dateInOffsetTime = getDateInOffsetTime(agenda.starttime);
    const title = `${agenda.name}, ${dateInOffsetTime.format(
      settings.monthAndTimeDisplayFormat
    )}`;
    return (
      <h2 className="agenda-title">
        {title}
        {isCloudVotingEnabled && isMeetingRunning && (
          <img className="padding-left-2" src={CvMeetingLiveIcon} alt='Live Meeting' id='agenda-live-meeting' />
        )}
      </h2>
    );
  };

  const renderAgendaPacketButton = () => {
    if (agenda.council_packet_status_id == 2 || agenda.agenda_packet_status_id == 2) {
      const packetUrl = validatePacketUrl(agenda);
      if (packetUrl) {
        return (
          <a className={classNames("agenda-packet-link", isCloudVotingEnabled ? "agenda-packet-link-cloud-voting" : "")}
            href={packetUrl} target="_blank" aria-label="Agenda Packet Link" rel="noreferrer">
            <Button unstyled={isCloudVotingEnabled} className="agenda-packet-button" size="sm">
              <FontAwesomeIcon
                icon={faFilePdf}
                style={{ marginRight: 4 }}
              />
              Agenda Packet
            </Button>
          </a>
        );
      } else {
        return null;
      }
    }
  }

  const updateMemberJoinedInput = {
    customer_uid: customerId,
    uid: memberId,
    availability_status: AvailabilityStatus.JOINED
  }

  const updateMemberLeftInput = {
    customer_uid: customerId,
    uid: memberId,
    availability_status: AvailabilityStatus.LEFT
  }

  const handleJoinMeetingClick = async () => {
    const meetingData = await invokeGraphqlOperation(getAgendaListByMeetingGuid, { customer_uid: customerId, meeting_guid: agendaUid }, authParameter);

    const filteredAndSortedMeetingData = meetingData
      ?.filter(item => [MeetingStatus.OPEN, MeetingStatus.RUNNING, MeetingStatus.PAUSED].includes(item.meeting_status))
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const votecastAgendaUid = filteredAndSortedMeetingData?.[0]?.uid ?? null;
    const votecastMeetingStatus = filteredAndSortedMeetingData?.[0]?.meeting_status ?? null;
    dispatch(setLiveMeeting({ meetingGuid: agendaUid, votecastAgendaUid: votecastAgendaUid, meetingStatus: votecastMeetingStatus }));

    if (filteredAndSortedMeetingData?.length > 0) {
      const updateMemberData = await invokeGraphqlOperation(updateMemberAvailabilityStatus, { input: updateMemberJoinedInput }, authParameter);

      if (updateMemberData && updateMemberData.availability_status === AvailabilityStatus.JOINED) {
        setMeetingJoined(true);
        dispatch(setMemberDetails({
          memberId: updateMemberData.uid,
          memberJoinStatus: true,
          isAttendeePresent: updateMemberData.status && updateMemberData.status.toLowerCase() === MemberAttendance.PRESENT,
          isChair: updateMemberData.chair,
          isVoter: updateMemberData.voting,
        }));
      }
    }
    else {
      // show error notification banner
      setIsMeetingOpenOrRunning(false);
      setIsMeetingRunning(false);
      dispatch(displayErrorNotification(ErrorNotificationMessage.JOIN_MEETING_ERROR));
    }
  }

  const handleLeaveMeetingClick = async () => {
    dispatch(setMemberDetails({ memberJoinStatus: false }));
    const updateMemberData = await invokeGraphqlOperation(updateMemberAvailabilityStatus, { input: updateMemberLeftInput }, authParameter);

    if (updateMemberData && updateMemberData.availability_status === AvailabilityStatus.LEFT) {
      setMeetingJoined(false);
      testOnLeaveMeeting(); //todo: test
      dispatch(setMemberDetails({
        memberId: updateMemberData.uid,
        memberJoinStatus: false,
      }));
      dispatch(setCalledSpeaker(undefined));
    }
  }

  const renderActiveAgenda = () => {
    return (
      <Page>
        <div className="agenda-title-container">
          <div className="agenda-title-side-container"></div>
          <div>{renderTitle()}</div>
          <div className="agenda-title-side-container">
            {isCloudVotingEnabled && (
              isMeetingOpenOrRunning && memberId ? (
                isMeetingJoined ? <LeaveMeetingButton onClick={handleLeaveMeetingClick} /> : <JoinMeetingButton onClick={handleJoinMeetingClick} />
              ) : (
                <JoinMeetingButton onClick={handleJoinMeetingClick} disabled={true} />
              )
            )}
            {!isCloudVotingEnabled && renderAgendaPacketButton()}
          </div>
        </div>
        <div id="agenda-content-container" className="agenda-content-container">
          {isCloudVotingEnabled && renderAgendaPacketButton()}
          <CardGroup className="active-agenda-content">
            <Card gridLayout={{ desktop: { col: 6 } }}>
              <AgendaItems itemsTreeData={treeData} />
            </Card>
            <Card gridLayout={{ desktop: { col: 6 } }} id="agenda-item-detail">
              <Container />
            </Card>
          </CardGroup>
        </div>
      </Page>
    );
  };

  if (isAgendaError || isNotesError || isBookmarksError) {
    return (
      <Page>
        <p>We encountered a problem while loading this agenda</p>
      </Page>
    );
  }

  if (isCurrentUserError /* || isCurrentPersonUidError */ && isCloudVotingEnabled) {
    return (
      <Page>
        <p>Error loading User data. Please try again later.</p>
      </Page>
    );
  }

  if (isLoading) {
    return (
      <Page>
        <div
          className="display-flex flex-column flex-align-center flex-justify-center text-blue"
          style={{ minHeight: 200 }}
        >
          <FontAwesomeIcon
            icon={"spinner"}
            size={"2x"}
            className="fa-spin"
            style={{ marginBottom: 8 }}
          />
          Loading...
        </div>
      </Page>
    );
  } else {
    return renderActiveAgenda();
  }
};

ActiveAgenda.displayName = "Agenda";


export default ActiveAgenda;
