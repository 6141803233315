import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import rootReducer from './rootReducer';

// https://redux-toolkit.js.org/api/configureStore
const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
    ]),
  devTools: { trace: true, traceLimit: 25 },
});

// configure listeners using the provided defaults
// enable refetchOnFocus, refetchOnReconnect... behaviors
setupListeners(store.dispatch);

global.store = store;

export default store;
