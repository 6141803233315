import {
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

import { AgendaStateEnum } from '../../../constants/eventConstants';

export const initialState = {
  id: '',
  date: '',
  title: '',
  startTimeFormatted: '',
  customer_id: '',
  starts_at: '',
  meetingGuid: '',
  list: [],
  state_enum: AgendaStateEnum.loading,
  // TODO --> explain key concept
  selectedItemKey: ''
};

const agenda = createSlice({
  name: 'agenda',
  initialState,
  reducers: {
    addMinutesItem: (state, action) => {
      let keyArr = !action.payload.agendaItemKey ? state.selectedItemKey.split('-') : action.payload.agendaItemKey.split('-');
      let idx = parseInt(keyArr.shift());
      let currItemArr = state.list;

      while (keyArr.length > 0) {
        currItemArr = currItemArr[`${idx}`].children;
        idx = parseInt(keyArr.shift());
      }
      // adds item as last child always
      currItemArr[`${idx}`].children.push({
        id: action.payload.id, // generated when the item is indexed.
        title: action.payload.title,
        type: action.payload.type,
        position: action.payload.position,
        parent_id: action.payload.parent_id,
        layout: 'item', // specifies what "kind" of item this is... section vs item. TBD
        agenda_id: state.id,
        note: null,
        agenda_number: null, // TODO --> if this is custom numbering, we will need to figure this out eventually....
        items: [],
        children: [],
        speakers: [],
        timestamp: action.payload.timestamp ?? null,
        is_note_private: action.payload?.is_note_private ?? false
      });

      // Move the selectedItemKey
      if (action.payload.setSelectedItemKey) {
        state.selectedItemKey = state.selectedItemKey + '-' +
          (currItemArr[`${idx}`].children.length - 1);
      }
    },
    deleteSelectedMinutesItem: (state, action) => {
      let isRootItem = action.payload.isRootItem;
      let currItemArr = state.list;
      let selectedItemKey = action.payload.selectedItemKey;

      if (isRootItem) {
        let itemToDeleteIndex = parseInt(selectedItemKey.split('-').pop());
        currItemArr.splice(itemToDeleteIndex, 1);

        for (let i = itemToDeleteIndex;i < currItemArr.length;i++) {
          currItemArr[`${i}`].position = i+1;
        }

        if (selectedItemKey !== '0') {
          state.selectedItemKey = (itemToDeleteIndex -1).toString();
        }
      } else {
        let keyArr = state.selectedItemKey.split('-');
        let idx = parseInt(keyArr.shift());

        while (keyArr.length > 0) {
          currItemArr = currItemArr[`${idx}`].children;
          idx = parseInt(keyArr.shift());
        }

        // Delete the single item, or the value specified by the payload.
        currItemArr.splice(idx, action.payload.deleteCount ?? 1);

        // Update position of items in children array after removal of item
        if (currItemArr.length > 0) {
          for (const [i, child] of currItemArr.entries()) {
            child.position = i + 1;
          }
        }

        // Move the selectedItemKey
        keyArr = state.selectedItemKey.split('-');
        keyArr.pop();

        state.selectedItemKey = idx === 0
          ? keyArr.join('-')
          : (keyArr.join('-')+ '-' + (idx - 1)).toString();
      }
    },
    addAgendaItem: (state, action) => {
      let isRootItem = action.payload.isRootItem;
      let currItemArr = state.list;

      if (isRootItem) {
        currItemArr.splice(action.payload.currentItemPosition, 0, {
          id: action.payload.id,
          title: action.payload.title,
          type: action.payload.type,
          position: action.payload.newItemPosition,
          parent_id: action.payload.parent_id,
          layout: action.payload.layout,
          agenda_id: state.id,
          note: null,
          agenda_number: null, // TODO --> if this is custom numbering, we will need to figure this out eventually....
          items: [],
          children: [],
          timestamp: null,
          origin: 'ad_hoc',
          uid: null,
          speakers: [],
          is_note_private: false,
          suggested_action: null,
          department: null,
          url: null
        });

        for (let i = action.payload.newItemPosition;i < currItemArr.length;i++) {
          currItemArr[`${i}`].position = i+1;
        }
      } else {
        let keyArr = action.payload.parentItemKey.split('-');
        let idx = parseInt(keyArr.shift());

        while (keyArr.length > 0) {
          currItemArr = currItemArr[`${idx}`].children;
          idx = parseInt(keyArr.shift());
        }

        currItemArr[`${idx}`].children.splice(action.payload.currentItemPosition,0,{
          id: action.payload.id,
          title: action.payload.title,
          type: action.payload.type,
          position: action.payload.newItemPosition,
          parent_id: action.payload.parent_id,
          layout: action.payload.layout,
          agenda_id: state.id,
          note: null,
          agenda_number: null, // TODO --> if this is custom numbering, we will need to figure this out eventually....
          items: [],
          children: [],
          timestamp: null,
          origin: 'ad_hoc',
          uid: null,
          speakers: [],
          is_note_private: false,
          suggested_action: null,
          department: null,
          url: null
        });

        // Update position of items in children array after insertion of item
        for (let i = action.payload.newItemPosition;i < currItemArr[`${idx}`].children.length;i++) {
          currItemArr[`${idx}`].children[`${i}`].position = i+1;
        }
      }
      if (action.payload.setSelectedItemKey) {
        state.selectedItemKey = action.payload.newItemKey;
      }

    },
    deleteSelectedAgendaItem: (state, action) => {
      let isRootItem = action.payload.isRootItem;
      let currItemArr = state.list;
      let parentItemKey = action.payload.parentItemKey;
      let selectedItemKey = action.payload.selectedItemKey;
      let itemToDeleteIndex = parseInt(selectedItemKey.split('-').pop());

      if (isRootItem) {
        currItemArr.splice(itemToDeleteIndex, 1);

        for (let i = itemToDeleteIndex;i < currItemArr.length;i++) {
          currItemArr[`${i}`].position = i+1;
        }

        if (selectedItemKey !== '0') {
          state.selectedItemKey = (itemToDeleteIndex -1).toString();
        }
      } else {
        let keyArr = parentItemKey.split('-');
        let idx = parseInt(keyArr.shift());

        while (keyArr.length > 0) {
          currItemArr = currItemArr[`${idx}`].children;
          idx = parseInt(keyArr.shift());
        }

        currItemArr[`${idx}`].children.splice(itemToDeleteIndex, 1);

        // Update position of items in children array after deletion of item
        if (currItemArr.length > 0) {
          for (let i = itemToDeleteIndex;i < currItemArr[`${idx}`].children.length;i++) {
            currItemArr[`${idx}`].children[`${i}`].position = i+1;
          }
        }

        state.selectedItemKey = itemToDeleteIndex === 0
          ? parentItemKey
          : (parentItemKey + '-' + (itemToDeleteIndex - 1)).toString();
      }
    },
    addVoteMinutesItem: (state, action) => {
      let currItemArr = state.list;
      let keyArr = action.payload.parentItemKey.split('-');
      let idx = parseInt(keyArr.shift());

      while (keyArr.length > 0) {
        currItemArr = currItemArr[`${idx}`].children;
        idx = parseInt(keyArr.shift());
      }

      currItemArr[`${idx}`].children.splice(action.payload.motionPosition,0,{
        id: action.payload.id,
        title: action.payload.title,
        type: action.payload.type,
        position: action.payload.position,
        parent_id: action.payload.parent_id,
        motion_item_id: action.payload.motion_item_id,
        layout: 'item',
        agenda_id: state.id,
        note: null,
        agenda_number: null,
        items: [],
        children: [],
        speakers: [],
        timestamp: null,
      });

      // Update position of items in children array after insertion of item
      for (let i = action.payload.position;i < currItemArr[`${idx}`].children.length;i++) {
        currItemArr[`${idx}`].children[i].position = i+1;
      }

      if (action.payload.setSelectedItemKey) {
        state.selectedItemKey = action.payload.newItemKey;
      }

    },
    addMotionMinutesItem: (state, action) => {
      let currItemArr = state.list;
      let keyArr = !action.payload.agendaItemKey ? state.selectedItemKey.split('-') : action.payload.agendaItemKey.split('-');
      let idx = parseInt(keyArr.shift());

      while (keyArr.length > 0) {
        currItemArr = currItemArr[`${idx}`].children;
        idx = parseInt(keyArr.shift());
      }
      // adds item as last child always
      currItemArr[`${idx}`].children.push({
        id: action.payload.id,
        title: action.payload.title,
        type: action.payload.type,
        position: action.payload.position,
        parent_id: action.payload.parent_id,
        layout: 'item',
        agenda_id: state.id,
        note: null,
        agenda_number: null,
        items: [],
        children: [],
        speakers: [],
        timestamp: action.payload.timestamp ?? null,
        motion_id: action.payload.motion_id
      });

      if (action.payload.setSelectedItemKey) {
        state.selectedItemKey = state.selectedItemKey + '-' +
          (currItemArr[`${idx}`].children.length - 1);
      }
    },

    deleteMotionMinutesItem: (state, action) => {
      if (action.payload.voteItem) {
        let currItemArr = state.list;
        let selectedItemKeyArr = state.selectedItemKey.split('-');
        let motionItemIdx = parseInt(selectedItemKeyArr.pop());
        let voteItemIdx = parseInt(action.payload.voteItem.position - 1);
        let keyArr = action.payload.parentItemKey.split('-');
        let idx = parseInt(keyArr.shift());

        while (keyArr.length > 0) {
          currItemArr = currItemArr[`${idx}`].children;
          idx = parseInt(keyArr.shift());
        }
        currItemArr[idx].children.splice(voteItemIdx, 1);
        currItemArr[idx].children.splice(motionItemIdx, 1);

        // Update position of items in children array after deletion of items
        if (currItemArr[idx].children.length > 0) {
          for (const [i, child] of currItemArr[idx].children.entries()) {
            child.position = i + 1;
          }
        }

        state.selectedItemKey = motionItemIdx === 0
          ? selectedItemKeyArr.join('-')
          : (selectedItemKeyArr.join('-') + '-' + (motionItemIdx - 1)).toString();

      } else {
        agenda.caseReducers.deleteSelectedMinutesItem(state, action);
      }
    },
    addNoteItem: (state, action) => {
      let isRootItem = action.payload.isRootItem;
      let currItemArr = state.list;

      if (isRootItem) {
        currItemArr.splice(action.payload.currentItemPosition, 0, {
          id: action.payload.id,
          title: action.payload.title,
          type: action.payload.type,
          position: action.payload.newItemPosition,
          parent_id: action.payload.parent_id,
          layout: action.payload.layout,
          agenda_id: state.id,
          note: null,
          agenda_number: null, // TODO --> if this is custom numbering, we will need to figure this out eventually....
          items: [],
          children: [],
          timestamp: null,
          origin: 'ad_hoc',
          uid: null,
          speakers: [],
          is_note_private: false,
          suggested_action: null,
          department: null,
          url: null
        });

        for (let i = action.payload.newItemPosition;i < currItemArr.length;i++) {
          currItemArr[`${i}`].position = i+1;
        }
      } else {
        let keyArr = action.payload.parentItemKey.split('-');
        let idx = parseInt(keyArr.shift());

        while (keyArr.length > 0) {
          currItemArr = currItemArr[`${idx}`].children;
          idx = parseInt(keyArr.shift());
        }

        currItemArr[`${idx}`].children.splice(action.payload.currentItemPosition,0,{
          id: action.payload.id,
          title: action.payload.title,
          type: action.payload.type,
          position: action.payload.newItemPosition,
          parent_id: action.payload.parent_id,
          layout: action.payload.layout,
          agenda_id: state.id,
          note: null,
          agenda_number: null, // TODO --> if this is custom numbering, we will need to figure this out eventually....
          items: [],
          children: [],
          timestamp: null,
          origin: 'ad_hoc',
          uid: null,
          speakers: [],
          is_note_private: false,
          suggested_action: null,
          department: null,
          url: null
        });

        for (let i = action.payload.newItemPosition;i < currItemArr[`${idx}`].children.length;i++) {
          currItemArr[`${idx}`].children[i].position = i+1;
        }
      }
      if (action.payload.setSelectedItemKey) {
        state.selectedItemKey = action.payload.newItemKey;
      }
    },
    deleteSelectedNoteItem: (state, action) => {
      let isRootItem = action.payload.isRootItem;
      let currItemArr = state.list;
      let parentItemKey = action.payload.parentItemKey;
      let selectedItemKey = action.payload.selectedItemKey;
      let itemToDeleteIndex = parseInt(selectedItemKey.split('-').pop());

      if (isRootItem) {
        currItemArr.splice(itemToDeleteIndex, 1);
        for (let i = itemToDeleteIndex; i < currItemArr.length; i++) {
          currItemArr[`${i}`].position = i+1;
        }
        if (selectedItemKey !== '0') {
          state.selectedItemKey = (itemToDeleteIndex -1).toString();
        }
      } else {
        let keyArr = parentItemKey.split('-');
        let idx = parseInt(keyArr.shift());

        while (keyArr.length > 0) {
          currItemArr = currItemArr[`${idx}`].children;
          idx = parseInt(keyArr.shift());
        }

        currItemArr[`${idx}`].children.splice(itemToDeleteIndex, 1);

        if (currItemArr.length > 0) {
          for (let i = itemToDeleteIndex;i < currItemArr[`${idx}`].children.length;i++) {
            currItemArr[`${idx}`].children[i].position = i+1;
          }
        }

        state.selectedItemKey = itemToDeleteIndex === 0
          ? parentItemKey
          : (parentItemKey + '-' + (itemToDeleteIndex - 1)).toString();
      }
    },
    receiveAgenda: (_state, action) => {
      return {
        id: action.payload.id,
        date: action.payload.date,
        title: action.payload.title,
        customer_id: action.payload.customer_id,
        starts_at: action.payload.starts_at,
        meetingGuid: action.payload.meetingGuid,
        startTimeFormatted: action.payload.startTimeFormatted,
        state_enum:
          action.payload.state_enum,
        list: action.payload.list,
        no_seconder_id: action.payload.no_seconder_id,
        no_seconder_name: action.payload.no_seconder_name,
        fail_no_seconder_id: action.payload.fail_no_seconder_id,
        fail_no_seconder_name: action.payload.fail_no_seconder_name,
        motion_actions: action.payload.motion_actions,
        selectedItemKey: action.payload.list.length === 0
          ? null
          : '0',
      };
    },
    setAgendaStateEnum: (state, action) => {
      return {
        ...state,
        state_enum: action.payload
      };
    },
    setSelectedItemKey: (state, action) => {
      return {
        ...state,
        selectedItemKey: action.payload
      };
    },
    setSpeakersForItem: (state, action) => {
      let keyArr = action.payload.selectedItemKey.split('-');
      let idx = parseInt(keyArr.shift());
      let currItemArr = state.list;

      while (keyArr.length > 0) {
        currItemArr = currItemArr[`${idx}`].children;
        idx = parseInt(keyArr.shift());
      }
      currItemArr[`${idx}`] = {
        ...currItemArr[`${idx}`],
        speakers: action.payload.speakers
      };
    },
    updateSpeakerForItem: (state, action) => {
      let keyArr = action.payload.selectedItemKey.split('-');
      let idx = parseInt(keyArr.shift());
      let currItemArr = state.list;

      while (keyArr.length > 0) {
        currItemArr = currItemArr[`${idx}`].children;
        idx = parseInt(keyArr.shift());
      }

      currItemArr[`${idx}`].speakers = currItemArr[`${idx}`].speakers.map(
        (speaker) => {
          if (speaker.id === action.payload.speaker.id) {
            return action.payload.speaker;
          } else {
            return speaker;
          }
        }
      );
    },
    deleteSpeakerForItem: (state, action) => {
      let keyArr = action.payload.selectedItemKey.split('-');
      let idx = parseInt(keyArr.shift());
      let currItemArr = state.list;

      while (keyArr.length > 0) {
        currItemArr = currItemArr[`${idx}`].children;
        idx = parseInt(keyArr.shift());
      }

      currItemArr[`${idx}`].speakers = currItemArr[`${idx}`].speakers.filter(
        speaker => speaker.id !== action.payload.id
      );
    },
    updateItemContent: (state, action) => {
      let keyArr = action.payload.selectedItemKey.split('-');
      let idx = parseInt(keyArr.shift());
      let currItemArr = state.list;

      while (keyArr.length > 0) {
        currItemArr = currItemArr[`${idx}`].children;
        idx = parseInt(keyArr.shift());
      }

      if (currItemArr[`${idx}`].type.toLowerCase() === 'agenda') {
        currItemArr[`${idx}`] = {
          ...currItemArr[`${idx}`],
          title: action.payload.title,
          note: action.payload.note,
          position: action.payload.position,
          parent_id: action.payload.parent_id,
          is_note_private: action.payload.is_note_private ?? false,
          suggested_action: action.payload.metadata?.suggested_action ?? '',
          department: action.payload.metadata?.department ?? ''
        };
      } else {
        currItemArr[`${idx}`] = {
          ...currItemArr[`${idx}`],
          title: action.payload.title,
          note: action.payload.note,
          position: action.payload.position,
          parent_id: action.payload.parent_id,
          is_note_private: action.payload.is_note_private
        };
      }
    },
    updateCurrentItemTimestamp: (state, action) => {
      let keyArr = state.selectedItemKey.split('-');
      let idx = parseInt(keyArr.shift());
      let currItemArr = state.list;

      while (keyArr.length > 0) {
        currItemArr = currItemArr[`${idx}`].children;
        idx = parseInt(keyArr.shift());
      }

      currItemArr[`${idx}`] = {
        ...currItemArr[`${idx}`],
        timestamp: action.payload.timestamp
      };
    }
  }
});

// Common access function for the currently selected item.
export const currentlySelectedItem = createSelector(
  [state => state.agenda.list, state => state.agenda.selectedItemKey],
  (arr, key) => {
    if (arr.length === 0 || !key) return null;
    let keyArr = key.split('-');
    let idx = parseInt(keyArr.shift());
    let currItemArr = arr;

    while (keyArr.length > 0) {
      currItemArr = currItemArr[`${idx}`].children;
      idx = parseInt(keyArr.shift());
    }

    return currItemArr[`${idx}`];
  }
);

export const generateMinutesSelectedItemKey = createSelector(
  [state => state.agenda.list, state => state.agenda.selectedItemKey],
  (arr, key) => {
    let keyArr = key.split('-');
    let idx = parseInt(keyArr.shift());
    let currItemArr = arr;

    while (keyArr.length > 0) {
      currItemArr = currItemArr[`${idx}`].children;
      idx = parseInt(keyArr.shift());
    }

    return key + '-' + (currItemArr[`${idx}`].children.length);
  }
);

export const latestRootTimestampedAgendaItem = createSelector(
  [state => state.agenda.list],
  (arr) => {
    let itemArr = arr;
    let latest = itemArr.reduce(
      (latestItem, item) => {
        // has to be agenda item
        // what to do when entrytime is null
        if (item.type.toLowerCase() === 'agenda') {
          if ((item.timestamp && latestItem.timestamp && item.timestamp > latestItem.timestamp) ||
            (item.timestamp && !latestItem.timestamp))
          {
            return item;
          }
          else {
            return latestItem;
          }
        } else {
          return latestItem;
        }
      }, itemArr[0]
    );

    return latest;
  }
);

export const getSelectedItemParent = createSelector(
  [(state) => state.agenda.list, (state) => state.agenda.selectedItemKey],
  (arr, key) => {
    if (key.split('-').length <= 1) {
      return null;
    }

    let keyArr = key.split('-');
    keyArr.pop();
    let idx = parseInt(keyArr.shift());
    let parent = arr[`${idx}`];

    while (keyArr.length > 0) {
      idx = parseInt(keyArr.shift());
      parent = parent.children[`${idx}`];
    }

    return parent;
  }
);

export const getNextSiblingFromKey = createSelector(
  [(state) => state.selectedItemKey, (state) => state.agenda.list],
  (selectedItemKey, itemList) => {
    let keyArr = selectedItemKey.split('-');
    let idx = parseInt(keyArr.shift());
    let currItemArr = itemList;

    while (keyArr.length > 0) {
      currItemArr = currItemArr[`${idx}`].children;
      idx = parseInt(keyArr.shift());
    }
    if (!currItemArr[`${parseInt(idx) + 1}`]) return null;
    return currItemArr[`${parseInt(idx) + 1}`];
  }
);

export const getVoteItemForMotionItem = createSelector(
  currentlySelectedItem,
  getSelectedItemParent,
  (currentItem, parentItem) => {
    if (currentItem.type.toLowerCase() !== 'motion') return null;
    let children = parentItem.children;
    let motionItemId = currentItem.id;
    let idx = currentItem.position - 1;
    for (; idx < children.length; idx++) {
      if (children[idx].type.toLowerCase() === 'vote') {
        if (children[idx].motion_item_id === motionItemId) {
          return children[idx];
        }
      }
    }
    return null;
  }
);

export const getVoteItemKeyForMotionItem = createSelector(
  (state) => state.agenda.selectedItemKey,
  currentlySelectedItem,
  getSelectedItemParent,
  (selectedItemKey, currentItem, parentItem) => {
    if (!selectedItemKey) return null;
    if (currentItem.type.toLowerCase() !== 'motion') return null;
    let keyArr = selectedItemKey.split('-');
    let idx = keyArr.pop();
    let children = parentItem.children;
    for (; idx < children.length; idx++) {
      if (children[idx].type.toLowerCase() === 'vote') {
        if (children[idx].motion_item_id === currentItem.id) {
          keyArr.push(idx);
          return keyArr.join('-');
        }
      }
    }
    return null;
  }
);

export const calculateVoteItemKeyForMotionItem = (selectedItemKey, currentItem, parentItem) => {
  if (!selectedItemKey) return null;
  if (currentItem.type.toLowerCase() !== 'motion') return null;
  let keyArr = selectedItemKey.split('-');
  let idx = keyArr.pop();
  let children = parentItem.children;
  for (; idx < children.length; idx++) {
    if (children[idx].type.toLowerCase() === 'vote') {
      if (children[idx].motion_item_id === currentItem.id) {
        keyArr.push(idx);
        return keyArr.join('-');
      }
    }
  }
  return null;
};

export const findKeysForAllChildrenByType = (baseItemChildren, baseItemKey, type, resultArr) => {
  for (const [i, item] of baseItemChildren.entries()) {
    let tempItem = Object.assign({}, item);
    tempItem.key = baseItemKey
      ? `${baseItemKey}-${i.toString()}`
      : i.toString();

    if (item.children.length > 0) {
      findKeysForAllChildrenByType(
        item.children,
        tempItem.key,
        type,
        resultArr
      );
    }

    if (tempItem.type.toLowerCase() === type.toLowerCase()) {
      resultArr.push({ key: tempItem.key });
    }
  }
  return resultArr;
};

export const findMotionIdsForAllChildren = (baseItemChildren, resultArr) => {
  for (const item of baseItemChildren) {

    if (item.children.length > 0) {
      findMotionIdsForAllChildren(
        item.children,
        resultArr
      );
    }

    if (item.type.toLowerCase() === 'motion') {
      resultArr.push({ motion_id: item.motion_id });
    }
  }
  return resultArr;
};

export const selectNoSeconderId = createSelector(
  [state => state.agenda.no_seconder_id], (id) => id
);

export const selectFailNoSeconderId = createSelector(
  [state => state.agenda.fail_no_seconder_id], (id) => id
);

export const selectCurrentItemSiblingPositionArr = createSelector(
  [(state) => state.agenda.selectedItemKey, (state) => state.agenda.list],
  (selectedItemKey, agendalist) => {
    let keyArr = selectedItemKey.split('-');
    let idx = parseInt(keyArr.shift());
    let currItemArr = agendalist;

    while (keyArr.length > 0) {
      currItemArr = currItemArr[`${idx}`].children;
      idx = parseInt(keyArr.shift());
    }

    return currItemArr.map((item) => ({ id: item.id, title: item.title, position: item.position }));
  }
);

export const calculateCurrentItemSiblingPositionArr = (selectedItemKey, agendaList) => {
  let keyArr = selectedItemKey.split('-');
  let idx = parseInt(keyArr.shift());
  let currItemArr = agendaList;

  while (keyArr.length > 0) {
    currItemArr = currItemArr[`${idx}`].children;
    idx = parseInt(keyArr.shift());
  }

  return currItemArr.map((item) => ({ id: item.id, title: item.title, position: item.position }));
};

export const {
  addMinutesItem,
  deleteSelectedMinutesItem,
  addAgendaItem,
  deleteSelectedAgendaItem,
  addVoteMinutesItem,
  addMotionMinutesItem,
  deleteMotionMinutesItem,
  addNoteItem,
  deleteSelectedNoteItem,
  receiveAgenda,
  setAgendaStateEnum,
  setSelectedItemKey,
  setSpeakersForItem,
  updateSpeakerForItem,
  deleteSpeakerForItem,
  updateItemContent,
  updateCurrentItemTimestamp
} = agenda.actions;

export default agenda.reducer;
