import {
  createSlice,
  createSelector
} from '@reduxjs/toolkit';

const initialState = [
  // debug data.
  // {
  //   id: '123',
  //   motion_to: 'Approve',
  //   motioner_id: '',
  //   seconder_id: ''
  // }
];

const motions = createSlice({
  name: 'motions',
  initialState,
  reducers: {
    addMotion: (state, action) => {
      return [
        ...state,
        {
          id: action.payload.motion_id,
          motion_to: action.payload.motion_to,
          motioner_id: action.payload.motionerId,
          seconder_id: action.payload.failNoSeconderId,
        }
      ];
    },
    deleteMotion: (state, action) => {
      return state.filter(motion => motion.id !== action.payload.motion_id);
    },
    receiveMotions: (_state, action) => {
      return action.payload;
    },
    updateMotionAction: (state, action) => {
      return state.map(motion => {
        if (motion.id === action.payload.motion_id) {
          return {
            ...motion,
            motion_to: action.payload.motion_to
          };
        } else {
          return motion;
        }
      });
    },
    updateMotionerId: (state, action) => {
      return state.map(motion => {
        if (motion.id === action.payload.motion_id) {
          return {
            ...motion,
            motioner_id: action.payload.motioner_id
          };
        } else {
          return motion;
        }
      });
    },
    updateSeconderId: (state, action) => {
      return state.map(motion => {
        if (motion.id === action.payload.motion_id) {
          return {
            ...motion,
            seconder_id: action.payload.seconder_id
          };
        } else {
          return motion;
        }
      });
    },
    updateMotionVotingStatus: (state, action) => {
      return state.map(motion => {
        if (motion.id === action.payload.motion_id) {
          return {
            ...motion,
            voting_status: action.payload.voting_status
          };
        } else {
          return motion;
        }
      });
    }
  }
});

export const selectMotion = createSelector(
  [state => state.motions, (state,motion_id) => motion_id],
  (motions, motion_id) => motions.find(motion => motion.id === motion_id)
);

export const selectMotionFromMotionsList = (motion_id, motions) => {
  return motions.find(motion => motion.id === motion_id);
};

export const selectCurrentItemsMotion = (childItems) => {
  return childItems?.filter(childItem => childItem?.type?.toLowerCase() === 'motion') || [];
};

export const selectInProgressMotionItemsCurrentItem = (childItems) => {
  return childItems?.find(childItem => childItem?.type?.toLowerCase() === 'motion' && !childItem?.timestamp) || [];
};

export const {
  addMotion,
  deleteMotion,
  receiveMotions,
  updateMotionAction,
  updateMotionerId,
  updateSeconderId,
  updateMotionVotingStatus
} = motions.actions;

export default motions.reducer;