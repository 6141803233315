import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
  cloudVotingEnable: false,
  cloudVotingEnableForClient: false,
  cloudVotingEnableForEvent: false,
};

const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettingValue: (state, action) => {
        state.cloudVotingEnable = action.payload.cloudVotingEnableForEvent && action.payload.cloudVotingEnableForClient;
        state.cloudVotingEnableForEvent = action.payload.cloudVotingEnableForEvent;
        state.cloudVotingEnableForClient = action.payload.cloudVotingEnableForClient;
    }
  }
});

// action creator for settingState reducer. This can be dispatched to update the state.
export const { setSettingValue } = settings.actions;

export default settings.reducer;