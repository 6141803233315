import { createSlice } from '@reduxjs/toolkit';
import { generateNewTimestamp } from '../../../utils/dateTimeUtil';
import { EventStateEnum, EventTransitionEnum } from '../../../constants/eventConstants';

export const initialState = {
  uid: '',
  startTime: null,
  streamUrl: null,
  broadcastId: null,
  eventState: EventStateEnum.Stopped,
  eventTransition: EventTransitionEnum.None,
  initialTimeRemainingSec: null,
  availableAttendees: [],
  eventExtended: false,
  exporting: false
};

const clip = createSlice({
  name: 'clip',
  initialState,
  reducers: {
    clipStarted: (state, action) => {
      return {
        ...state,
        uid: action.payload.uid,
        startTime: action.payload.startTime ?? generateNewTimestamp(),
        streamUrl: action.payload.streamUrl,
        broadcastId: action.payload.broadcastId
      };
    },
    setAvailableAttendees: (state, action) => {
      state.availableAttendees = action.payload;
    },
    setClipExporting: (state, action) => {
      state.exporting = action.payload;
    },
    setEventExtended: (state, action) => {
      state.eventExtended = action.payload;
    },
    setEventState: (state, action) => {
      state.eventState = action.payload.eventState;
    },
    setIsEventAutoStopped: (state, action) => {
      state.isEventAutoStopped = action.payload.isEventAutoStopped
    },
    setEventTransition: (state, action) => {
      state.eventTransition = action.payload.eventTransition;
    },
    setInitialTimeRemainingSec: (state, action) => {
      state.initialTimeRemainingSec = action.payload;
    },
    setClipUidNoEncoder: (state, action) => {
      return {
        ...state,
        uid: action.payload.uid,
        startTime: action.payload.startTime ?? generateNewTimestamp(),
      }
    },
  }
});

export const {
  clipStarted,
  setAvailableAttendees,
  setClipExporting,
  setEventExtended,
  setEventState,
  setIsEventAutoStopped,
  setEventTransition,
  setInitialTimeRemainingSec,
  setClipUidNoEncoder
} = clip.actions;

export default clip.reducer;