import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { displayErrorNotification } from '../../common/liveMeetingSlice';
import { ErrorNotificationMessage } from '../../../constants/cloudVotingConstants';

const ErrorNotification = () => {

  const dispatch = useDispatch();
  const errorMessageNeedRefresh = [ErrorNotificationMessage.JOIN_MEETING_ERROR];

  const closeErrorNotification = (errorMessage) => {
    dispatch(displayErrorNotification(null));
    //To reload the page after closing the errror notification
    if (errorMessageNeedRefresh.includes(errorMessage)) {
      window.location.reload();
    }
  };

  const errorMessage = useSelector(state => state.liveMeeting.errorMessage);

  return (
    <div id="error-container">
      <div id="error-info-panel">
        <div className="error-notification-header">
          <FontAwesomeIcon icon={['fas', 'info-circle']} size="lg" />
          <p>{errorMessage}</p>
          <button 
            id="close-error"
            aria-label="Close"
            className="close-button"
            onClick={() => closeErrorNotification(errorMessage)}>
            <FontAwesomeIcon icon={['fas', 'times']} size="sm" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorNotification;