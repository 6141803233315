import React from "react";
import ReactModal from "react-modal";
import VotingInProgress from "./VotingInProgress";
import VotingResults from "./VotingResults";
import { useSelector } from "react-redux";
import { selectIsCloudVotingEnabled } from "../../../common/settingSlice";

const VotingModal = () => {
  const isCloudVotingEnabled = useSelector(selectIsCloudVotingEnabled);
  if (!isCloudVotingEnabled) {
    return null;
  }

  const voteState = useSelector(state => state.liveMeeting.voteState);
  if (!voteState) {
    return null;
  }

  const getParent = () => document.querySelector("#agenda-content-container");

  return (
    <ReactModal
      isOpen={true}
      shouldCloseOnOverlayClick={false}
      parentSelector={getParent}
      className="voting-modal-content"
      overlayClassName="voting-modal-overlay"
    >
      <div className="voting-section">
        <VotingInProgress />
      </div>
      <div className="voting-section">
        <VotingResults />
      </div>
    </ReactModal>
  );
};

VotingModal.displayName = "VotingModal";

export default VotingModal;
