import React from "react";
import { Button } from "@trussworks/react-uswds";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { resetVote, selectVoteState, setCurrentVote, withDrawCurrentVote } from "../../../common/liveMeetingSlice";
import VoteButton from "./VoteButton";
import { MeetingStatus, VotecastRequestOrigin, VoteActionEnum, VotecastBulkAction, VotecastAudience, VotecastVotingStatus } from '../../../../constants/cloudVotingConstants';
import { getBallotByParentItemId } from '../../../../../amplify/graphql/queries';
import {
  updateVotecastBallot,
  createVotecastBallots,
  updateVotecastAgendaItemBulkAction
} from '../../../../../amplify/graphql/mutations';
import { processChunks } from '../../../../helpers/votecastWebHelpers/openCloseMeetingActions';
import { invokeGraphqlOperation } from "../../../../helpers/votecastWebHelpers/graphqlOperations";
import { updateVotingStatus } from "../../../../components/new_limaweb/actions/voteActions.js";

const VotingInProgress = () => {
  const dispatch = useDispatch();
  const voteState = useSelector(selectVoteState);
  const userName = useSelector(state => state.auth.username);
  const customerId = useSelector(state => state.auth.customer_uid);
  const votecastAgendaUid = useSelector(state => state.liveMeeting.votecastAgendaUid);
  const meetingStatus = useSelector(state => state.liveMeeting.meetingStatus);
  const memberDetails = useSelector(state => state.liveMeeting.memberDetails);

  const authParameter = { customerId: customerId, audience: VotecastAudience.LEGISLATE };

  const handleResetVote = async () => {
    try {
      dispatch(resetVote());
      const ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: voteState.voteId }, authParameter);
      if (ballotsData && ballotsData.length > 0) {
        const createOrUpdateBallotInputList = ballotsData.map(voter => ({
          ...voter,
          customer_uid: customerId,
          agenda_uid: votecastAgendaUid,
          voting_configuration_name: '',
          request_origin: VotecastRequestOrigin.ATTENDEE_CHAIR_UPDATE
        }));

        const updateVotecastAgendaItemBulkActionInput = {
          uid: voteState.voteId,
          agenda_uid: votecastAgendaUid,
          bulk_action: VotecastBulkAction.ATTENDEE_CHAIR_RESET_VOTES
        }

        // AppSync doesn't support batch updates, so using BatchPut as an upsert operation.
        // It will insert a new item if the combination of partition and sort keys doesn't exist,
        // or update the existing item if it does.
        await processChunks(Object.values(createOrUpdateBallotInputList), createVotecastBallots, authParameter);
        await invokeGraphqlOperation(updateVotecastAgendaItemBulkAction, { input: updateVotecastAgendaItemBulkActionInput }, authParameter);
      }
    } catch (error) {
      console.log('VoteInProgress::handleResetVote', error);
    }
  };

  const handleStopVoting = async () => {
    const uid = voteState?.motionId;
    const agenda_uid = votecastAgendaUid;
    const customer_uid = customerId;
    const request_origin = VotecastRequestOrigin.ATTENDEE_CHAIR_UPDATE;
    const voting_status = VotecastVotingStatus.STOPPED;
    dispatch(updateVotingStatus(uid, agenda_uid, customer_uid, voting_status, request_origin, VotecastAudience.LEGISLATE));
  };

  const handleVote = async (value) => {
    let updateBallotInput = {
      uid: voteState.ballotId,
      motion_uid: voteState.motionId,
      member_name: userName,
      member_uid: memberDetails.memberId,
      voting_configuration_name: value
    };

    if (voteState.currentVote?.voting_configuration_name === value) {
      // Making voting_configuration_name empty in case of withdraw vote
      updateBallotInput = {
        ...updateBallotInput,
        voting_configuration_name: ""
      }
      dispatch(withDrawCurrentVote());
    } else {
      dispatch(setCurrentVote(updateBallotInput));
    }

    // Preparing input for updateVotecastBallot graphql call
    updateBallotInput = {
      ...updateBallotInput,
      customer_uid: customerId,
      agenda_uid: votecastAgendaUid,
      request_origin: VotecastRequestOrigin.ATTENDEE_UPDATE
    };

    await invokeGraphqlOperation(updateVotecastBallot, { input: updateBallotInput }, authParameter);
  };

  const resetVoteButton = (
    <div className="reset-voting-container">
      <Button type="button" unstyled onClick={handleResetVote} disabled={meetingStatus === MeetingStatus.PAUSED}>
        <div className="reset-voting-icone">
          <FontAwesomeIcon icon={['fas', 'undo']} />
        </div>
        Reset Vote
      </Button>
    </div>
  );

  const stopVotingButton = (
    <div className="stop-voting-container">
      <Button type="button" unstyled onClick={handleStopVoting} disabled={meetingStatus === MeetingStatus.PAUSED}>
        <div className="stop-voting-icone">
          <FontAwesomeIcon icon={['fas', 'times-circle']} />
        </div>
        Stop Voting
      </Button>
    </div>
  );

  const voteContent = (
    <>
      <ContentItem
        title="Item Text"
        text={voteState.itemText ?? "Placeholder"}
      />
      <ContentItem
        title="Motion Text"
        text={voteState.motionText?.trim() ? voteState.motionText : "N/A"}
      />
      <ContentItem
        title="Suggested Action"
        text={voteState.motionAction?.trim() ? voteState.motionAction : "N/A"}
      />
      <ContentItem title="Motion To" text={voteState.motionTo ?? "Amend"} />

      <div>
        <ContentItem title="Mover" text={voteState.motionMover ?? "Name"} />
        <ContentItem
          title="Seconder"
          text={voteState.motionSeconder ?? "Name"}
        />
      </div>
    </>
  );

  const voteButtons = (
    <>
      <VoteButton
        value={VoteActionEnum.Yes}
        className={(!(memberDetails.isAttendeePresent && memberDetails.isVoter) || meetingStatus === MeetingStatus.PAUSED) ? "usa-button-outline-disabled" : "usa-button-outline-green"}
        onClick={handleVote}
        selected={voteState.currentVote?.voting_configuration_name === VoteActionEnum.Yes}
        isDisabled={(!(memberDetails.isAttendeePresent && memberDetails.isVoter) || meetingStatus === MeetingStatus.PAUSED)}
      />

      <VoteButton
        value={VoteActionEnum.No}
        className={(!(memberDetails.isAttendeePresent && memberDetails.isVoter) || meetingStatus === MeetingStatus.PAUSED) ? "usa-button-outline-disabled" : "usa-button-outline-grenadier"}
        onClick={handleVote}
        selected={voteState.currentVote?.voting_configuration_name === VoteActionEnum.No}
        isDisabled={(!(memberDetails.isAttendeePresent && memberDetails.isVoter) || meetingStatus === MeetingStatus.PAUSED)}
      />

      <VoteButton
        value={VoteActionEnum.Abstain}
        className={(!(memberDetails.isAttendeePresent && memberDetails.isVoter) || meetingStatus === MeetingStatus.PAUSED) ? "usa-button-outline-disabled" : "usa-button-outline-blue"}
        onClick={handleVote}
        selected={voteState.currentVote?.voting_configuration_name === VoteActionEnum.Abstain}
        isDisabled={(!(memberDetails.isAttendeePresent && memberDetails.isVoter) || meetingStatus === MeetingStatus.PAUSED)}
      />
    </>
  );

  return (
    <div className="voting-inprogress-container">
      <div className="voting-inprogress-header display-flex">
        <div className="flex-1">
          <span className="voting-inprogress-header-title">
            Voting in Progress
          </span>
        </div>
        <div className="flex-auto margin-x-2">{(memberDetails.isAttendeePresent && memberDetails.isChair) ? resetVoteButton : undefined}</div>
        <div className="flex-auto">{(memberDetails.isAttendeePresent && memberDetails.isChair) ? stopVotingButton : undefined}</div>
      </div>
      <div className="voting-section-body">{voteContent}</div>
      <div className="voting-section-footer">{voteButtons}</div>
    </div>
  );
};

const ContentItem = ({ title, text }) => (
  <div>
    <div className="sub-title">{title}</div>
    <div className="sub-text">{text}</div>
  </div>
);

VotingInProgress.displayName = "VotingInProgress";

export default VotingInProgress;
