import {
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

const attendees = createSlice({
  name: 'attendees',
  initialState: [],
  reducers: {
    addAttendees: (state, action) => {
      // 8/2021 Not using Chair. If added later uncomment below.
      // Handle the case that if a newly added attendee is marked as chair,
      // we need to unset the previously set chair.
      action.payload.newAttendeesList.map(newAttendee => {
        if (newAttendee.chair) {
          state.map(attendee => attendee.chair ? { ...attendee, chair: false } : attendee);
        }
      });
      // Note: It is possible to add a new attendee with the same name as an existing attendee
      return state.concat(action.payload.newAttendeesList);
    },
    revertAddAttendees: (state, action) => {
      let numToDelete = action.payload.newAttendeesList.length;
      state.splice(state.length-numToDelete, numToDelete);
    },
    deleteAttendee: (state, action) => {
      return state.filter(attendee => attendee.id !== action.payload.id);
    },
    revertDeleteAttendee: (state, action) => {
      state.splice(action.payload.index, 0, action.payload.attendee);
    },
    setAttendees: (_state, action) => {
      return action.payload;
    },
    updateChairStatus: (state, action) => {
      return state.map(attendee =>
        attendee.id === action.payload.id
          ? { ...attendee, chair: !attendee.chair }
          : { ...attendee, chair: false }
      );
    },
    updateAttendee: (state, action) => {
      return state.map(attendee =>
        attendee.id === action.payload.id
          ? action.payload // whole member object.
          : attendee
      );
    }
  }
});

export const selectAvailableVoters = createSelector(
  [state => state.attendees],
  (attendees) => attendees.filter(
    attendee =>
      attendee.voting === true &&
      attendee.status === 'present'
  )
);

export const selectDefaultMotioner = createSelector(
  [state => state.attendees],
  (attendees) => attendees.find(
    attendee =>
      attendee.voting === true &&
      attendee.status === 'present'
  )
);

export const {
  addAttendees,
  revertAddAttendees,
  deleteAttendee,
  revertDeleteAttendee,
  setAttendees,
  updateAttendee,
  updateChairStatus,
} = attendees.actions;

export default attendees.reducer;