import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  votecastMeetingStatus: '',
  selectedItemId: '',
  isToggleOn: false,
  requestedMotion: {
    motionNotification: false,
    motionId: '',
    motioner_id: '',
    motioner_name: '',
    seconder_id: '',
    seconder_name: ''
  },
  currentSpeaker: {
    speakerId: '',
    speakerTimeRemaining: 0,
    currentSpeakerIndex: null,
    speakerTimerRunning: false,
    action: null,
    currentSpeakerItemId: '',
    status: ''
  },
  votecastWebSpeakers: [],
  votecastWebMotions:[],
  votingStatus: false
};

const liveMeetingLima = createSlice({
  name: 'liveMeetingLima',
  initialState,
  reducers: {
    setVotecastMeetingStatus: (state, action) => {
      state.votecastMeetingStatus = action.payload;
    },
    setSelectedItemId: (state, action) => {
      state.selectedItemId = action.payload;
    },
    setIsToggleOn: (state, action) => {
      state.isToggleOn = action.payload;
    },
    setRequestedMotion: (state, { payload }) => {
      state.requestedMotion = {
        ...state.requestedMotion,
        ...payload
      };
    },
    setCurrentSpeaker: (state, { payload }) => {
      state.currentSpeaker = {
        ...state.currentSpeaker,
        ...payload
      }
    },
    setvotecastWebSpeakers: (state, { payload }) => {
      state.votecastWebSpeakers = payload;
    },
    setvotecastWebMotions: (state, { payload }) => {
      state.votecastWebMotions = payload;
    },
    updateVotecastWebMotionVotingStatus: (state, { payload }) => {
      const motionIndex = state.votecastWebMotions.findIndex(motion => motion.uid === payload.motion_id);
      if (motionIndex !== -1) {
        state.votecastWebMotions[motionIndex] = {
          ...state.votecastWebMotions[motionIndex],
          voting_status: payload.voting_status
        };
      }
    }
  }
});

export const {
  setVotecastMeetingStatus,
  setSelectedItemId,
  setIsToggleOn,
  setRequestedMotion,
  setCurrentSpeaker,
  setvotecastWebSpeakers,
  setvotecastWebMotions,
  updateVotecastWebMotionVotingStatus,
} = liveMeetingLima.actions;

export default liveMeetingLima.reducer;