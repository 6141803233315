import { invokeGraphqlOperation, getJWTToken, displayErrorMessage } from './graphqlOperations';
import GraphQLAPI, { graphqlOperation } from '@aws-amplify/api';
import { VotecastAudience,JWTTokenExpiryTimeInSec, ErrorNotificationMessage  } from '../../constants/cloudVotingConstants';
import {
  createVotecastMember,
  createAgenda,
  createMembers,
  createCustomer,
  createVotecastAgendaItems,
  createMotionResults,
  createMotionTypes,
  createVotecastSpeakers,
  deleteVotecastAgenda,
  deleteVotecastClip,
  deleteVotecastAgendaItems,
  deleteVotecastMotions,
  deleteVotecastSpeakers,
  deleteMembers,
  deleteVotecastBallots,
  deleteVotecastClips
} from '../../../amplify/graphql/mutations';

import {
  getVotecastClipsByAgendaUid,
  getVotecastAgendaItemsByAgendaUid,
  getVotecastMotionsByAgendaUid,
  getVotecastMembersByAgendaUid,
  getVotecastSpeakersByAgendaId
} from '../../../amplify/graphql/queries';

//Perform individual GraphQL API calls for each table to manipulate data
export const openMeeting = async (inputs) => {
  const {
    agendaInput,
    customerInput,
    agendaItemsArray: agendaItemsInput,
    motionResultInput: motionResultsInput,
    motionTypeInput: motionTypesInput,
    speakersArray: speakersInput,
    memberInput: membersInput,
  } = inputs;

  const authParameter = { customerId: customerInput.uid, audience: VotecastAudience.LIMA_WEB };

  try {
    const addedAgenda = await invokeGraphqlOperation(createAgenda, { input: agendaInput }, authParameter);
    const addedCustomer = await invokeGraphqlOperation(createCustomer, { input: customerInput }, authParameter);
    const addedAgendaItems = await processChunks(agendaItemsInput, createVotecastAgendaItems, authParameter);
    const addedMotionResults = await processChunks(motionResultsInput, createMotionResults, authParameter);
    const addedMotionTypes = await processChunks(motionTypesInput, createMotionTypes, authParameter);
    let addedSpeakers = [];
    if (speakersInput && speakersInput.length > 0) {
      addedSpeakers = await processChunks(speakersInput, createVotecastSpeakers, authParameter);
    }
    let addedAttendees = [];
    try {
      if (membersInput && Object.keys(membersInput).length > 0) {
        //addedAttendees = await processChunks(Object.values(membersInput), createMembers, authParameter);
        let authToken = await getJWTToken(membersInput[0]?.customer_uid, VotecastAudience.LIMA_WEB);
        let jwtTokenExpiryTime = Date.now() + (JWTTokenExpiryTimeInSec * 1000);
        addedAttendees = await Promise.all(  membersInput.map(async (memberInput) => {
          if ((jwtTokenExpiryTime - Date.now()) < (30 * 1000)) {
            authToken = await getJWTToken(membersInput[0]?.customer_uid, VotecastAudience.LIMA_WEB);
            jwtTokenExpiryTime = Date.now() + (JWTTokenExpiryTimeInSec * 1000);
          }
          const memberResponse = await GraphQLAPI.graphql(graphqlOperation(createVotecastMember, { input: memberInput }, authToken));
          const error = memberResponse?.data?.error?.errors?.[0]?.toLowerCase() || '';
          if (error) {
            console.log("Mutation error createVotecastMember: ", error);
            displayErrorMessage(ErrorNotificationMessage.MUTATION_ERROR_NOTIFICATION, VotecastAudience.LIMA_WEB);
          }
          return memberResponse;
        }));
      }
    } catch (error) {
      console.log("Mutation error createVotecastMember: ", error);
      displayErrorMessage(ErrorNotificationMessage.MUTATION_ERROR_NOTIFICATION, VotecastAudience.LIMA_WEB);
    }
    
    return {
      success: true,
      results: {
        addedAgenda,
        addedAttendees,
        addedCustomer,
        addedAgendaItems,
        addedMotionResults,
        addedMotionTypes,
        addedSpeakers,
      },
    };
  } catch (error) {
    console.error("An error occurred in openCloseMeetingActions:", error);
    return {
      success: false,
      error,
    };
  }
};

export const closeMeeting = async (closeMeetingInput) => {
  const {
    deleteAgendaInput,
    deleteClipInput,
    deleteMotionsInput,
    agendaItemsArray: deleteAgendaItemsInput,
    speakersArray: deleteSpeakersInput,
    deleteMembersInput, deleteVotesInput } = closeMeetingInput;

  const authParameter = { customerId: deleteAgendaInput.customer_uid, audience: VotecastAudience.LIMA_WEB };

  try {
    let deletedClip;
    const deletedAgenda = await invokeGraphqlOperation(deleteVotecastAgenda, { customer_uid: deleteAgendaInput.customer_uid, uid: deleteAgendaInput.uid }, authParameter);
    if (deleteClipInput.uid) {
      deletedClip = await invokeGraphqlOperation(deleteVotecastClip, { agenda_uid: deleteClipInput.agenda_uid, uid: deleteClipInput.uid }, authParameter);
    }
    const deletedAgendaItems = await processChunks(deleteAgendaItemsInput, deleteVotecastAgendaItems, authParameter);
    let deletedMotions = [];
    if (deleteMotionsInput && deleteMotionsInput.length > 0) {
      deletedMotions = await processChunks(Object.values(deleteMotionsInput), deleteVotecastMotions, authParameter);
    }
    let deletedVotes = [];
    if (deleteVotesInput && deleteVotesInput.length > 0) {
      deletedVotes = await processChunks(Object.values(deleteVotesInput), deleteVotecastBallots, authParameter);
    }
    let deletedSpeakers = [];
    if (deleteSpeakersInput && deleteSpeakersInput.length > 0) {
      deletedSpeakers = await processChunks(deleteSpeakersInput, deleteVotecastSpeakers, authParameter);
    }
    let deletedAttendees = [];
    if (deleteMembersInput && Object.keys(deleteMembersInput).length > 0) {
      deletedAttendees = await processChunks(Object.values(deleteMembersInput), deleteMembers, authParameter);
    }

    return {
      success: true,
      results: {
        deletedAgenda,
        deletedAttendees,
        deletedClip,
        deletedAgendaItems,
        deletedMotions,
        deletedVotes,
        deletedSpeakers,
      },
    };
  } catch (error) {
    console.error("An error occurred while deleting the data from dynamoDB:", error);
    return {
      success: false,
      error,
    };
  }
}

const fetchAndMapData = async (operation, queryPayload, authParameter) => {
  let response = await invokeGraphqlOperation(operation, queryPayload, authParameter);

  // Handle the case where response contains an items array for getVotecastSpeakersByAgendaId
  if (operation === getVotecastSpeakersByAgendaId && response?.items) {
    response = response.items;
  }

  if (!response || !Array.isArray(response)) {
    console.error('Unexpected response format:', response);
    return [];
  }

  return response.map(item => {
    const result = { uid: item.uid };

    if (operation === getVotecastMembersByAgendaUid) {
      result.customer_uid = item.customer_uid;
    } else if (operation === getVotecastSpeakersByAgendaId) {
      result.item_uid = item.item_uid;
    } else {
      result.agenda_uid = item.agenda_uid;
    }

    return result;
  });
};

export const deleteStaleData = async (inputs) => {
  try {
    const queryPayload = {
      customer_uid: inputs?.agendaInput?.customer_uid,
      agenda_uid: inputs?.agendaInput?.uid
    };
    const authParameter = { customerId: inputs?.agendaInput?.customer_uid, audience: VotecastAudience.LIMA_WEB };

    const [agendaItemsToBeDeleted, clipsToBeDeleted, motionsToBeDeleted, membersToBeDeleted, speakersToBeDeleted] = await Promise.all([
      fetchAndMapData(getVotecastAgendaItemsByAgendaUid, queryPayload, authParameter),
      fetchAndMapData(getVotecastClipsByAgendaUid, queryPayload, authParameter),
      fetchAndMapData(getVotecastMotionsByAgendaUid, queryPayload, authParameter),
      fetchAndMapData(getVotecastMembersByAgendaUid, queryPayload, authParameter),
      fetchAndMapData(getVotecastSpeakersByAgendaId, { agenda_uid: queryPayload.agenda_uid }, authParameter)
    ]);

    const deletedAgenda = await invokeGraphqlOperation(deleteVotecastAgenda,
      { customer_uid: inputs?.agendaInput?.customer_uid, uid: inputs?.agendaInput?.uid }, authParameter);
    let deletedAgendaItems = [];
    if (agendaItemsToBeDeleted && agendaItemsToBeDeleted.length > 0) {
      deletedAgendaItems = await processChunks(Object.values(agendaItemsToBeDeleted), deleteVotecastAgendaItems, authParameter);
    }
    let deletedClips = [];
    if (clipsToBeDeleted && clipsToBeDeleted.length > 0) {
      deletedClips = await processChunks(Object.values(clipsToBeDeleted), deleteVotecastClips, authParameter);
    }
    let deletedMotions = [];
    if (motionsToBeDeleted && motionsToBeDeleted.length > 0) {
      deletedMotions = await processChunks(Object.values(motionsToBeDeleted), deleteVotecastMotions, authParameter);
    }
    let deletedMembers = [];
    if (membersToBeDeleted && membersToBeDeleted.length > 0) {
      deletedMembers = await processChunks(Object.values(membersToBeDeleted), deleteMembers, authParameter);
    }
    let deletedSpeakers = [];
    if (speakersToBeDeleted && speakersToBeDeleted.length > 0) {
      deletedSpeakers = await processChunks(speakersToBeDeleted, deleteVotecastSpeakers, authParameter);
    }

    return {
      success: true,
      results: {
        deletedAgenda,
        deletedAgendaItems,
        deletedClips,
        deletedMotions,
        deletedMembers,
        deletedSpeakers
      }
    };
  } catch (error) {
    console.error("An error occurred while deleting stale data from dynamoDB:", error);
    return {
      success: false,
      error,
    };
  }
}

export async function processChunks(input, operation, authParameter) {
  //Passing the graphQL method through this helper method will auto handle batch operation limits by AWS
  const chunkSize = 25; // Adjust based on operation limits, AWS can perform batch operation up to 25 items only per request
  const chunks = chunkArray(input, chunkSize);
  let results = [];
  const authToken = await getJWTToken(authParameter?.customerId, authParameter.audience);
  for (const chunk of chunks) {
    try {
      const result = await GraphQLAPI.graphql(graphqlOperation(operation, { input: chunk }, authToken));
      const error = result?.data?.error?.errors?.[0]?.toLowerCase() || '';
      if (error) {
        displayErrorMessage(ErrorNotificationMessage.MUTATION_ERROR_NOTIFICATION, authParameter.audience);
        console.error('Error processing graphql batch:', error);
      }
      results.push(result);
    } catch (error) {
      console.error('Error processing graphql batch:', error);
      displayErrorMessage(ErrorNotificationMessage.MUTATION_ERROR_NOTIFICATION, authParameter.audience);
    }
  }
  return results;
}

function chunkArray(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}