import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseQueryWithoutReauth = fetchBaseQuery({
  baseUrl: '/',
  prepareHeaders(headers) {
    return headers;
  },
  credentials: 'include',
});

/**
 * This baseQuery will be use in Redux Api
 */
export const baseQuery = async(args, api, extraOptions) => {
  let result = await baseQueryWithoutReauth(args, api, extraOptions);
  return result;
};
