import { combineReducers } from 'redux';
import authReducer from '../common/authSlice';
import { agendaApi } from '../services/agendaApi';
import { settingApi } from '../services/settingApi';
import { customerApi } from '../services/customerApi';
import vcdLiveMeetingReducer from '../common/vcdLiveMeetingSlice';
import errorReducer from '../common/errorSlice';
import { eventApi } from '../services/eventApi';

const appReducer = combineReducers({
  [settingApi.reducerPath]: settingApi.reducer,
  [agendaApi.reducerPath]: agendaApi.reducer,
  [customerApi.reducerPath]: customerApi.reducer,
  [eventApi.reducerPath]: eventApi.reducer,
  authVCD: authReducer,
  vcdLiveMeeting: vcdLiveMeetingReducer,
  error: errorReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
