import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  memberDetails: {
    memberId: '',
    memberJoinStatus: false,
    isAttendeePresent: false,
    isChair: false,
    isVoter:false
  },
  motionDetails: {
    isMotionEnabled: false,
    allowMotions: false,
    motionId: '',
    motionItemId: '',
    position: '',
    motionTo: '',
    motionMover: '',
    motionSeconder: '',
    previousMotion: undefined, // { motionTo: 'To', mover: 'Mover here', seconder: 'seconder here', }
  },
  currentActiveAgendaItem: {
    currentItemName: '',
    currentItemGuid: '',
    currentItemHypatiaId: '',
    metadata: null
  },
  agendaItemsDictionary: {},
  isVotingStarted: false,
  canChairStartVote: false,
  meetingGuid: '',
  votecastAgendaUid: '',
  meetingStatus: '',
  allowJoin: false,
  errorMessage: null,
  voteState: undefined,
  voteInProgressState: undefined
};

const liveMeeting = createSlice({
  name: 'liveMeeting',
  initialState,
  reducers: {
    setLiveMeeting: (state, { payload: { meetingGuid, votecastAgendaUid, meetingStatus } }) => {
      state.meetingGuid = meetingGuid;
      state.votecastAgendaUid = votecastAgendaUid;
      state.meetingStatus = meetingStatus;
    },
    setLiveMeetingStatus: (state, { payload }) => {
      state.meetingStatus = payload;
    },
    setMemberDetails: (state, { payload }) => {
      state.memberDetails = {
        ...state.memberDetails,
        ...payload,
      };
    },
    setMotionDetails: (state, { payload }) => {
      state.motionDetails = {
        ...state.motionDetails,
        ...payload,
      };
    },
    setAgendaItemsDictionary: (state, { payload }) => {
      state.agendaItemsDictionary = payload;
    },
    updateAgendaItemMetadata: (state, action) => {
      const { mema_metadata_id, metadata } = action.payload;
      if (state.agendaItemsDictionary[mema_metadata_id]) {
        state.agendaItemsDictionary[mema_metadata_id].metadata = metadata;
      }
    },
    setCurrentActiveItem: (state, { payload }) => {
      state.currentActiveAgendaItem = {
        ...state.currentActiveAgendaItem,
        ...payload
      };
    },
    setIsVotingStarted: (state, { payload }) => {
      state.isVotingStarted = payload;
    },
    setCanChairStartVote: (state, { payload }) => {
      state.canChairStartVote = payload;
    },
    displayErrorNotification: (state, {payload}) => {
      state.errorMessage = payload;
    },
    setVoteStarted: (state, { payload }) => {
      state.voteState = {
        ...state.voteState,
        ...payload
      };
    },
    updateVoteMotionAction: (state, { payload }) => {
      state.voteState = {
        ...state.voteState,
        motionAction: payload?.motionAction,
      };
    },
    setVotesChanged: (state, { payload }) => {
      //vote: {uid, motion_uid. member_name, member_uid, voting_configuration_name}
      state.voteState.votes = payload;
    },
    setVotesInProgressChanged: (state, { payload }) => {
      state.voteInProgressState.votes = payload;
    },
    setCurrentVote: (state, { payload }) => {
      // {uid, motion_uid. member_name, member_uid, voting_configuration_name}
      state.voteState = state.voteState || {};
      state.voteState.votes = state.voteState.votes || [];

      if (payload.member_uid === state.memberDetails.memberId) {
        state.voteState.currentVote = payload;
      }

      const index = state.voteState.votes.findIndex(v => v.member_uid === payload.member_uid);
      if (index !== -1) {
        state.voteState.votes[index] = payload;
      } else {
        state.voteState.votes.push(payload);
      }
    },
    setCurrentVoteInProgress: (state, { payload }) => {
      // {uid, motion_uid. member_name, member_uid, voting_configuration_name}
      if (payload.member_uid === state.memberDetails.memberId) {
        state.voteInProgressState.currentVote = payload;
      }

      const index = state.voteInProgressState.votes.findIndex(v => v.member_uid === payload.member_uid);
      if (index !== -1) {
        state.voteInProgressState.votes[index] = payload;
      } else {
        state.voteInProgressState.votes.push(payload);
      }
    },
    withDrawCurrentVote: (state) => {
      const memberId = state.voteState.currentVote.member_uid;
      const index = state.voteState.votes.findIndex(v => v.member_uid === memberId);
      if (index !== -1) {
        state.voteState.votes.splice(index, 1);
      }
      state.voteState.currentVote = null;
    },
    resetVote: (state) => {
      state.voteState.votes = state.voteState.votes.map(vote => ({
        ...vote,
        voting_configuration_name: ""
      }));
      state.voteState.currentVote = null;
    },
    setVoteStop: (state) => {
      state.voteState = null;
    },
    setVoteInProgressStop: (state) => {
      state.voteInProgressState = null;
    },
    setVoteInProgressState: (state, { payload }) => {
      const voteInProgressState = {
        itemUid: payload?.itemUid,
        itemText: payload?.itemText,
        motionId: payload?.motionId,
        voteId: payload?.voteId,
        motionText: payload?.motionText,
        motionAction: payload?.motionAction,
        motionTo: payload?.motionTo,
        motionMover: payload?.motionMover,
        motionSeconder: payload?.motionSeconder,
        ballotId: payload?.ballotId,
        votes: payload?.votes,
        currentVote: payload?.currentVote,
      };
      state.voteInProgressState = voteInProgressState;
    },
    setVoteResume: (state, { payload }) => {
      const voteState = {
        itemUid: payload?.itemUid,
        itemText: payload?.itemText,
        motionId: payload?.motionId,
        voteId: payload?.voteId,
        motionText: payload?.motionText,
        motionAction: payload?.motionAction,
        motionTo: payload?.motionTo,
        motionMover: payload?.motionMover,
        motionSeconder: payload?.motionSeconder,
        ballotId: payload?.ballotId,
        votes: payload?.votes,
        currentVote: payload?.currentVote,
      };
      state.voteState = voteState;
    },
    resetData: () => initialState,
  },
});

export default liveMeeting.reducer;

export const {
  setMemberDetails,
  setMotionDetails,
  setAgendaItemsDictionary,
  setIsVotingStarted,
  setCanChairStartVote,
  setLiveMeeting,
  setLiveMeetingStatus,
  setCurrentActiveItem,
  resetData,
  setVoteStarted,
  setVotesChanged,
  setVotesInProgressChanged,
  setCurrentVote,
  setCurrentVoteInProgress,
  withDrawCurrentVote,
  setVoteStop,
  setVoteInProgressState,
  setVoteResume,
  setVoteInProgressStop,
  resetVote,
  displayErrorNotification,
  updateVoteMotionAction,
  updateAgendaItemMetadata
} = liveMeeting.actions;

/**
 * Summary. Get all live meeting state
 */
export const selectLiveMeeting = (state) => state.liveMeeting;

export const selectMotionState = (state) => {
  const {
    liveMeeting,
    auth: { username },
  } = state;
  return {
    motionId: liveMeeting.motionDetails.motionId,
    motionItemId: liveMeeting.motionDetails.motionItemId,
    username: username,
    allowMotions: liveMeeting.motionDetails.allowMotions,
    motionTo: liveMeeting.motionDetails.motionTo,
    position: liveMeeting.motionDetails.position,
    mover: liveMeeting.motionDetails.motionMover,
    seconder: liveMeeting.motionDetails.motionSeconder,
    previousMotion: liveMeeting.motionDetails.previousMotion,
    userCanMotion:
      liveMeeting.motionDetails.allowMotions &&
      !liveMeeting.motionDetails.motionMover &&
      liveMeeting.memberDetails.isVoter &&
      liveMeeting.memberDetails.isAttendeePresent,
    userCanSecond:
      liveMeeting.motionDetails.allowMotions &&
      !!liveMeeting.motionDetails.motionMover &&
      liveMeeting.motionDetails.motionMover !== username &&
      !liveMeeting.motionDetails.motionSeconder &&
      liveMeeting.memberDetails.isVoter &&
      liveMeeting.memberDetails.isAttendeePresent,
    isUserSubmittedMotion: username === liveMeeting.motionDetails.motionMover,
    isUserSubmittedSecond:
      username === liveMeeting.motionDetails.motionSeconder,
  };
};

export const selectIsVotingStarted = (state) =>
  state.liveMeeting.isVotingStarted;

export const selectCanChairStartVote = (state) => state.liveMeeting.canChairStartVote;
export const selectVoteState = (state) => state.liveMeeting.voteState;
export const selectVoteInProgressState = (state) => state.liveMeeting.voteInProgressState;

export const selectVotes = (state) => state.liveMeeting.voteState.votes;
