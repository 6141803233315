import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectItemKeys,
  selectSelectedItemKey,
  setSelectedItemKey,
} from './activeAgendaSlice';
import { Tree } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
  setLiveMeetingStatus,
  setMotionDetails,
  setCurrentActiveItem,
  setAgendaItemsDictionary,
  setVoteStop,
  setIsVotingStarted,
  setMemberDetails,
  setVotesChanged,
  setCurrentVote,
  setVoteStarted,
  setVoteInProgressState,
  setVoteResume,
  setVoteInProgressStop,
  setCanChairStartVote,
  setVotesInProgressChanged,
  setCurrentVoteInProgress,
  displayErrorNotification,
  updateVoteMotionAction,
  updateAgendaItemMetadata
} from "../../common/liveMeetingSlice";
import { setSpeakers, setCalledSpeaker } from '../../common/liveMeetingSpeakerSlice';
import {
  MemberAttendance, VotecastRequestOrigin, AgendaItemActiveStatus, AgendaItemType, SpeakerStatus, VotecastBulkAction, VoteActionEnum,
  VotecastAudience, VotecastVotingStatus, ErrorNotificationMessage
} from "../../../constants/cloudVotingConstants";
import GraphQLAPI, { graphqlOperation } from '@aws-amplify/api';
import {
  getVotecastAgendaItems,
  getMotionByAgendaItemId,
  getVotecastSpeakersByAgendaId,
  getActiveAgendaItem,
  getBallotByParentItemId,
  getVotecastAgendaItemsByParentUid,
  getVotecastMotionById,
} from '../../../../amplify/graphql/queries';
import {
  onAgendaItemRecord,
  onMeetingEndUpdate,
  onVotecastMemberUpdate,
  onVotecastDeleteAttendee,
  onMotionUpdate,
  onBallotUpdate,
  onCreateUpdateAgendaItemForVote,
  onUpdateVotecastAgendaItemMetadata,
  OnUpdateVotecastAgendaMeetingStatus,
  onVotecastDeleteAgendaItem
} from '../../../../amplify/graphql/subscriptions';
import { invokeGraphqlOperation, invokeGraphqlOperationWithNextToken, getJWTToken } from '../../../helpers/votecastWebHelpers/graphqlOperations';
import { toast } from 'react-toastify';
import VotingModal from '../LiveMeeting/Voting/VotingModal';
import VotingResultModal from '../LiveMeeting/Voting/VotingResultModal';
import { updateVotingStatus } from "../../../components/new_limaweb/actions/voteActions";
import { selectIsCloudVotingEnabled } from '../../common/settingSlice';

const AgendaItems = (props) => {
  const dispatch = useDispatch();
  const selectedItemKey = useSelector(selectSelectedItemKey);
  const itemKeys = useSelector(selectItemKeys);
  const isCloudVotingEnabled = useSelector(selectIsCloudVotingEnabled);
  const memberJoinStatus = useSelector(state => state.liveMeeting.memberDetails?.memberJoinStatus);
  const memberDetails = useSelector(state => state.liveMeeting.memberDetails);
  const motionDetails = useSelector(state => state.liveMeeting.motionDetails);
  const votecastAgendaUid = useSelector(state => state.liveMeeting.votecastAgendaUid);
  const currentActiveItem = useSelector(state => state.liveMeeting.currentActiveAgendaItem);
  const motionDetailsRef = useRef(motionDetails);
  const currentUserRef = useRef(memberDetails);
  const voteInProgressState = useSelector((state) => state.liveMeeting.voteInProgressState);
  const voteInProgressStateRef = useRef(voteInProgressState);
  const currentActiveItemRef = useRef(currentActiveItem);
  const voteState = useSelector((state) => state.liveMeeting.voteState);
  const voteStateRef = useRef(voteState);
  const isVotingStarted = useSelector(state => state.liveMeeting.isVotingStarted);

  const customerId = useSelector(state => state.auth.customer_uid);
  const userName = useSelector(state => state.auth.username);

  const [showVotingResult, setShowVotingResult] = useState(false);
  const [votingResult, setVotingResult] = useState(null); // 'Passed' or 'Failed'

  const handleClose = () => setShowVotingResult(false);

  const showItemDetail = (itemKey) => {
    dispatch(setSelectedItemKey(itemKey));
  };

  const handleSubscriptionError = (error) => {
    const errorMessage = error?.error?.errors?.[0]?.message?.toLowerCase() || '';

    if (errorMessage) {
      if (errorMessage.includes('connection closed') || errorMessage.includes('connection timeout')) {
        dispatch(displayErrorNotification(ErrorNotificationMessage.SUBSCRIPTION_TIMEOUT_NOTIFICATION));
      } else {
        dispatch(displayErrorNotification(ErrorNotificationMessage.SUBSCRIPTION_ERROR_NOTIFICATION));
      }
    } else {
      console.error('Unknown error structure:', error);
      dispatch(displayErrorNotification(ErrorNotificationMessage.SUBSCRIPTION_ERROR_NOTIFICATION));
    }
  };

  useEffect(() => {
    if (typeof (selectedItemKey) !== 'undefined' && selectedItemKey.length > 0) {
      const selectedNode = document.getElementsByClassName('ant-tree-treenode-selected')[0];
      if (selectedNode) {
        const topPosition = selectedNode.offsetTop;

        const agendaItemsContainer = document.getElementById('agenda-items-container');
        if (agendaItemsContainer) {
          agendaItemsContainer.scrollTop = topPosition - 10;
        }
      }
    }
  }, [props]);

  const authParameter = { customerId: customerId, audience: VotecastAudience.LEGISLATE };

  useEffect(() => {
    if (isCloudVotingEnabled) {
      motionDetailsRef.current = motionDetails;
      currentActiveItemRef.current = currentActiveItem;
      voteStateRef.current = voteState;
      currentUserRef.current = memberDetails;
      voteInProgressStateRef.current = voteInProgressState;
    }
  }, [voteState, motionDetails, isCloudVotingEnabled, memberDetails, voteInProgressState, currentActiveItem]);

  useEffect(() => {
    if (isCloudVotingEnabled && memberJoinStatus && customerId && votecastAgendaUid) {
      onJoinMeeting(votecastAgendaUid);
    }
  }, [isCloudVotingEnabled, customerId && memberJoinStatus]);

  const onJoinMeeting = async (votecastAgendaUid) => {
    const agendaItemsData = await invokeGraphqlOperation(getVotecastAgendaItems, { agenda_uid: votecastAgendaUid }, authParameter);
    if (agendaItemsData && agendaItemsData.length > 0) {
      const agendaItemsDictionary = agendaItemsData.reduce((acc, item) => {
        acc[item.mema_metadata_id] = item;
        return acc;
      }, {});
      dispatch(setAgendaItemsDictionary(agendaItemsDictionary));

      // get and set the active agenda item
      const activeAgendaItem = agendaItemsData.find(item => item.status === AgendaItemActiveStatus.ACTIVE && item.type === AgendaItemType.Agenda && item.entrytime);
      if (activeAgendaItem) {
        dispatch(setCurrentActiveItem({
          currentItemGuid: activeAgendaItem.mema_metadata_id,
          currentItemName: activeAgendaItem.title,
          currentItemHypatiaId: activeAgendaItem.uid,
          metadata: activeAgendaItem.metadata
        }));
        dispatch(setMotionDetails({ allowMotions: true }));

        const activeMotionItem = agendaItemsData.find(item => item.type === AgendaItemType.Motion
          && !item.entrytime && item.parent_uid === activeAgendaItem.uid);

        const parentItemId = activeMotionItem ? activeMotionItem.uid : activeAgendaItem.uid;
        const activeMotionData = await invokeGraphqlOperation(getMotionByAgendaItemId,
          { agenda_uid: votecastAgendaUid, parent_agenda_item_uid: parentItemId }, authParameter);

        if (activeMotionData && activeMotionData.length > 0) {
          const motionInProgress = activeMotionData[0];
          if (motionInProgress) {
            dispatch(setMotionDetails({
              isMotionEnabled: true,
              motionId: motionInProgress.uid,
              position: motionInProgress.position,
              motionTo: motionInProgress.motion_action_name,
              motionMover: motionInProgress.motioner_name,
              motionSeconder: motionInProgress.seconder_name
            }));
          }
        }
        // Find the previous motion with the latest Timestamped Motion
        const motionItemsWithEntryTime = agendaItemsData.filter(item => item.type === AgendaItemType.Motion
          && item.entrytime && item.parent_uid === activeAgendaItem.uid);
        let previousMotion = null;
        if (motionItemsWithEntryTime && motionItemsWithEntryTime.length > 0) {
          const latestRecordedMotionItem = motionItemsWithEntryTime
            .sort((a, b) => b.position - a.position)[0];
          if (latestRecordedMotionItem) {
            const motionUid = JSON.parse(latestRecordedMotionItem.metadata);
            if (motionUid?.motion_id) {
              previousMotion = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: motionUid.motion_id }, authParameter);
              if (previousMotion !== null) {
                dispatch(setMotionDetails({
                  previousMotion: {
                    motionTo: previousMotion.motion_action_name,
                    mover: previousMotion.motioner_name,
                    seconder: previousMotion.seconder_name,
                    motionId: previousMotion.uid,
                    motionText: previousMotion.text
                  }
                }));
              }
            }
          }
        }

        // votes - Offline support
        const voteAgendaItemData = agendaItemsData.find(item => item.parent_uid === activeAgendaItem.uid && item.type === AgendaItemType.Vote && !item.entrytime);
        if (voteAgendaItemData) {
          const voteItemMetadata = JSON.parse(voteAgendaItemData?.metadata ?? "{}");
          const motionData = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: voteItemMetadata?.motion_id }, authParameter);
          if (motionData && motionData.voting_status === VotecastVotingStatus.STARTED) {
            const metadata = JSON.parse(activeAgendaItem?.metadata ?? "{}");
            dispatch(setVoteStarted({
              itemUid: activeAgendaItem?.uid,
              itemText: activeAgendaItem?.title,
              voteId: voteAgendaItemData.uid,
              motionId: motionData.uid,
              motionText: motionData?.text ?? "",
              motionAction: metadata?.suggested_action ?? "",
              motionTo: motionData?.motion_action_name ?? "",
              motionMover: motionData?.motioner_name ?? "",
              motionSeconder: motionData?.seconder_name ?? ""
            }));
            dispatch(setIsVotingStarted(true));
            const ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: voteAgendaItemData.uid }, authParameter);
            if (ballotsData && ballotsData.length > 0) {
              const currentUserVote = ballotsData.find(vote => vote.member_uid === currentUserRef.current.memberId);
              dispatch(setVoteStarted({ ballotId: currentUserVote?.uid }));
              dispatch(setVotesChanged(ballotsData));
              if (currentUserVote) {
                dispatch(setCurrentVote(currentUserVote));
              }
            }
            dispatch(setCanChairStartVote(true));
          } else if (motionData && motionData.voting_status === VotecastVotingStatus.STOPPED) {
            const metadata = JSON.parse(activeAgendaItem?.metadata ?? "{}");
            dispatch(setIsVotingStarted(true));
            dispatch(setVoteStop());
            let ballotsData = null;
            let currentUserVote = null;
            ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: voteAgendaItemData.uid }, authParameter);
            if (ballotsData && ballotsData.length > 0) {
              currentUserVote = ballotsData.find(vote => vote.member_uid === currentUserRef.current.memberId);
            }
            if (ballotsData !== null && currentUserVote !== null) {
              let payload = {
                ballotId: currentUserVote?.uid,
                itemUid: activeAgendaItem?.uid,
                itemText: activeAgendaItem?.title,
                voteId: voteAgendaItemData.uid,
                motionId: motionData.uid,
                motionText: motionData?.text ?? "",
                motionAction: metadata?.suggested_action ?? "",
                motionTo: motionData?.motion_action_name ?? "",
                motionMover: motionData?.motioner_name ?? "",
                motionSeconder: motionData?.seconder_name ?? "",
                votes: ballotsData,
                currentVote: currentUserVote
              };
              dispatch(setVoteInProgressState(payload));
            }
            dispatch(setCanChairStartVote(true));
          }
        } else {
          // Condition where there is no incomplete vote
          if (previousMotion === null || previousMotion === undefined) {
            dispatch(setCanChairStartVote(false));
          } else {
            if (!(previousMotion.seconder_name === 'Failed for lack of second' || previousMotion.seconder_name == "")) {
              if (
                previousMotion?.voting_status === null ||
                previousMotion?.voting_status === "N/A" ||
                previousMotion?.voting_status === ""
              ) {
                dispatch(setCanChairStartVote(true));
              } else if (previousMotion?.voting_status === VotecastVotingStatus.COMPLETED) {
                dispatch(setCanChairStartVote(false));
              }
            } else {
              dispatch(setCanChairStartVote(false));
            }
          }
        }
      }
    }

    // get the list of speakers for agenda_id
    const speakersData = await invokeGraphqlOperationWithNextToken(getVotecastSpeakersByAgendaId, { agenda_uid: votecastAgendaUid }, authParameter);
    if (speakersData && speakersData.length > 0) {
      dispatch(setSpeakers(speakersData));
      const calledSpeaker = speakersData.find(sp => sp.status && [SpeakerStatus.CALLED, SpeakerStatus.STARTED, SpeakerStatus.STOPPED].includes(sp.status));
      if (calledSpeaker) {
        dispatch(setCalledSpeaker({
          ...calledSpeaker,
          duration: calledSpeaker.duration === -1 ? 0 : calledSpeaker.duration,
        }));
      }
    }
  };

  // Appsync graphql subscription- motion update
  useEffect(() => {
    let motionUpdateSubscription;
    const startMotionUpdateSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        motionUpdateSubscription = await GraphQLAPI.graphql(graphqlOperation(onMotionUpdate,
          { customer_uid: customerId, agenda_uid: votecastAgendaUid }, authToken
        )).subscribe({
          next: async (response) => {
            const motionData = response.value.data.onVotecastMotionUpdate;
            if (motionData && motionData.request_origin === VotecastRequestOrigin.CLERK_CREATE) {
              dispatch(setMotionDetails({
                motionId: motionData.uid,
                motionTo: motionData.motion_action_name,
                motionItemId: motionData.parent_agenda_item_uid,
                motionMover: motionData?.motioner_name ?? "",
                motionSeconder: motionData?.seconder_name ?? "",
              }));
            } else if (motionData && motionDetails.motionId == '' && motionData.request_origin === VotecastRequestOrigin.CLERK_DELETE) {
              dispatch(setMotionDetails({
                motionTo: '',
                motionMover: '',
                motionSeconder: '',
              }));
            }
            else if (
              motionData &&
              (
                (
                  motionData.request_origin === VotecastRequestOrigin.ATTENDEE_CHAIR_UPDATE ||
                  motionData.request_origin === VotecastRequestOrigin.CLERK_UPDATE
                ) &&
                (
                  motionData?.voting_status === VotecastVotingStatus.STARTED ||
                  motionData?.voting_status === VotecastVotingStatus.STOPPED
                )
              )
            ) {
              if (motionData.voting_status === VotecastVotingStatus.STOPPED) {
                if (voteStateRef.current !== undefined && voteStateRef.current !== null) {
                  dispatch(setVoteInProgressState(voteStateRef.current));
                  dispatch(setVoteStop());
                  dispatch(setCanChairStartVote(true));
                }
              } else if (motionData.voting_status === VotecastVotingStatus.STARTED) {
                if (voteInProgressStateRef.current !== undefined && voteInProgressStateRef.current !== null) {
                  dispatch(setIsVotingStarted(true));
                  dispatch(setVoteResume(voteInProgressStateRef.current));
                }
              }
            }
            else {
              if (motionData && motionData.uid === motionDetailsRef.current?.motionId) {
                // If request is from clerk side and entrytime is present in the response. => Clerk has recorded/completed the motion.
                if (motionData.entrytime && motionData.request_origin === VotecastRequestOrigin.CLERK_UPDATE) {
                  dispatch(setMotionDetails({
                    motionId: '',
                    motionItemId: '',
                    motionTo: '',
                    motionMover: '',
                    motionSeconder: '',
                    previousMotion: {
                      motionTo: motionData.motion_action_name,
                      mover: motionData.motioner_name,
                      seconder: motionData.seconder_name,
                      motionId: motionData.uid,
                      motionText: motionData.text
                    }
                  }));

                  if (!(motionData.seconder_name === 'Failed for lack of second' || motionData.seconder_name == "")) {
                    // Get all motions for active agenda item
                    const motionsForActiveAgendaItem = await invokeGraphqlOperation(getVotecastAgendaItemsByParentUid,
                      { parent_uid: currentActiveItemRef.current?.currentItemHypatiaId, type: AgendaItemType.Motion }, authParameter);
                    const latestRecordedMotionItem = motionsForActiveAgendaItem
                      .filter(motion => motion.entrytime)
                      .sort((a, b) => b.position - a.position)[0];

                    let latestRecordedMotionDetail = null;
                    if (latestRecordedMotionItem) {
                      const motionUid = JSON.parse(latestRecordedMotionItem.metadata);
                      if (motionUid?.motion_id) {
                        latestRecordedMotionDetail = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: motionUid.motion_id }, authParameter);
                      }
                    }
                    // console.log('latestRecordedMotionDetail on motion item record', latestRecordedMotionDetail);
                    if (latestRecordedMotionDetail === null || latestRecordedMotionDetail === undefined) {
                      dispatch(setCanChairStartVote(false));
                    } else {
                      if (
                        latestRecordedMotionDetail?.voting_status === null ||
                        latestRecordedMotionDetail?.voting_status === "N/A" ||
                        latestRecordedMotionDetail?.voting_status === ""
                      ) {
                        dispatch(setCanChairStartVote(true));
                      } else if (latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.COMPLETED) {
                        dispatch(setCanChairStartVote(false));
                      }
                      // else if(
                      //   latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.STARTED || 
                      //   latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.STOPPED
                      // ){
                      //   dispatch(setCanChairStartVote(true));
                      //   // Get data for vote to resume and set in voteInProgressState
                      // }
                    }
                  } else {
                    dispatch(setCanChairStartVote(false));
                  }

                  if (motionData.seconder_name === 'Failed for lack of second' || motionData.seconder_name == "") {
                    toast("The Motion Failed", motionToastConfig);
                  }
                }
              }
              if (motionData && motionData.uid && !motionData.entrytime) {
                if (motionData.motioner_name != null) {
                  if (memberJoinStatus && ![userName, ""].includes(motionData.motioner_name) &&
                    motionDetailsRef.current?.motionMover === "" &&
                    [VotecastRequestOrigin.ATTENDEE_CREATE, VotecastRequestOrigin.ATTENDEE_UPDATE].includes(motionData.request_origin)) {
                    toast(`${motionData.motioner_name} has Motioned.`, motionToastConfig);
                  }
                  dispatch(setMotionDetails({ motionMover: motionData.motioner_name }));
                }
                if (motionData.seconder_name != null) {
                  if (memberJoinStatus && ![userName, ""].includes(motionData.seconder_name) &&
                    motionDetailsRef.current?.motionSeconder === "" && motionData.request_origin === VotecastRequestOrigin.ATTENDEE_UPDATE) {
                    toast(`${motionData.seconder_name} has Seconded.`, motionToastConfig);
                  }
                  dispatch(setMotionDetails({ motionSeconder: motionData.seconder_name }));
                }
                dispatch(setMotionDetails({
                  isMotionEnabled: true,
                  motionItemId: motionData.parent_agenda_item_uid,
                  motionId: motionData.uid,
                  motionTo: motionData.motion_action_name,
                  position: motionData.position
                }));
              }
            }
          },
          error: (error) => {
            console.error('Subscription: motionUpdateSubscription error:', error);
            handleSubscriptionError(error);
          }
        });
      } catch (error) {
        console.error('Error initializing motionUpdateSubscription:', error);
        handleSubscriptionError(error);
      }
    }
    if (isCloudVotingEnabled && customerId && votecastAgendaUid && memberJoinStatus) {
      startMotionUpdateSubscription();
    }
    return () => {
      if (motionUpdateSubscription) motionUpdateSubscription.unsubscribe();
    };
  }, [customerId, votecastAgendaUid, memberJoinStatus]);

  // Appsync graphql subscription- delete motion and Votes
  useEffect(() => {
    let motionVoteDeletesubscription;
    const startMotionVoteDeletesubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        motionVoteDeletesubscription = await GraphQLAPI.graphql(graphqlOperation(onVotecastDeleteAgendaItem, { agenda_uid: votecastAgendaUid }, authToken
        )).subscribe({
          next: async (response) => {
            const deleteAgendaItemData = response.value.data.onVotecastDeleteAgendaItem;
            if (deleteAgendaItemData) {
              if (deleteAgendaItemData.type === AgendaItemType.Motion) {
                const metadata = JSON.parse(deleteAgendaItemData?.metadata);
                if (metadata?.motion_id === motionDetailsRef.current?.motionId) {
                  dispatch(setMotionDetails({
                    motionId: '',
                    motionTo: '',
                    motionMover: '',
                    motionSeconder: '',
                  }));
                  // Also reset the related vote
                  if (voteStateRef.current && metadata?.motion_id === voteStateRef.current?.motionId) {
                    dispatch(setVoteStop());
                  }
                }
              } else if (deleteAgendaItemData.type === AgendaItemType.Vote) {
                dispatch(setIsVotingStarted(false));
                if (voteStateRef?.current && deleteAgendaItemData.uid === voteStateRef.current?.voteId) {
                  dispatch(setVoteStop());
                }
                if (voteInProgressStateRef.current && deleteAgendaItemData.uid === voteInProgressStateRef.current?.voteId) {
                  dispatch(setVoteInProgressStop());
                }
                // Get Deleted Vote Item Linked Motion
                const metadata = deleteAgendaItemData?.metadata ? JSON.parse(deleteAgendaItemData.metadata) : null;
                const linkedVotecastMotion = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: metadata?.motion_id }, authParameter);
                // console.log("linkedVotecastMotion", linkedVotecastMotion);
                if (linkedVotecastMotion) {
                  // Mark voting_status for that motion to N/A
                  const uid = metadata?.motion_id;
                  const agenda_uid = votecastAgendaUid;
                  const customer_uid = customerId;
                  const request_origin = VotecastRequestOrigin.CLERK_UPDATE;
                  const voting_status = 'N/A';
                  dispatch(updateVotingStatus(uid, agenda_uid, customer_uid, voting_status, request_origin, VotecastAudience.LEGISLATE));
                }
              }

              if (deleteAgendaItemData?.type === AgendaItemType.Motion || deleteAgendaItemData?.type === AgendaItemType.Vote) {
                // Get all motions for active agenda item
                const motionsForActiveAgendaItem = await invokeGraphqlOperation(getVotecastAgendaItemsByParentUid,
                  { parent_uid: currentActiveItemRef.current?.currentItemHypatiaId, type: AgendaItemType.Motion }, authParameter);
                const latestRecordedMotionItem = motionsForActiveAgendaItem
                  .filter(motion => motion.entrytime)
                  .sort((a, b) => b.position - a.position)[0];

                let latestRecordedMotionDetail = null;
                if (latestRecordedMotionItem) {
                  const motionUid = JSON.parse(latestRecordedMotionItem.metadata);
                  if (motionUid?.motion_id) {
                    latestRecordedMotionDetail = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: motionUid.motion_id }, authParameter);
                  }
                }
                // If Motion Deleted
                if (deleteAgendaItemData?.type === AgendaItemType.Motion) {
                  dispatch(setMotionDetails(
                    {
                      previousMotion: latestRecordedMotionDetail !== null ? {
                        motionTo: latestRecordedMotionDetail?.motion_action_name,
                        mover: latestRecordedMotionDetail?.motioner_name,
                        seconder: latestRecordedMotionDetail?.seconder_name,
                        motionId: latestRecordedMotionDetail?.uid,
                        motionText: latestRecordedMotionDetail?.text
                      } : undefined
                    }
                  ));
                }
                // console.log('latestRecordedMotionDetail on delete vote or motion item', latestRecordedMotionDetail);
                if (latestRecordedMotionDetail === null || latestRecordedMotionDetail === undefined) {
                  dispatch(setCanChairStartVote(false));
                } else {
                  if (!(latestRecordedMotionDetail.seconder_name === 'Failed for lack of second' || latestRecordedMotionDetail.seconder_name == "")) {
                    if (
                      latestRecordedMotionDetail?.voting_status === null ||
                      latestRecordedMotionDetail?.voting_status === "N/A" ||
                      latestRecordedMotionDetail?.voting_status === ""
                    ) {
                      dispatch(setCanChairStartVote(true));
                    } else if (latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.COMPLETED) {
                      dispatch(setCanChairStartVote(false));
                    }
                    // else if(
                    //   latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.STARTED || 
                    //   latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.STOPPED
                    // ){
                    //   dispatch(setCanChairStartVote(true));
                    //   // Get data for vote to resume and set in voteInProgressState
                    // }
                  } else {
                    dispatch(setCanChairStartVote(false));
                  }
                }
              }
            }
          },
          error: (error) => {
            console.error('Subscription: motionVoteDeletesubscription error:', error);
            handleSubscriptionError(error);
          }
        });
      } catch (error) {
        console.error('Error initializing motionVoteDeletesubscription:', error);
        handleSubscriptionError(error);
      }
    };
    if (isCloudVotingEnabled && customerId && votecastAgendaUid && memberJoinStatus) {
      startMotionVoteDeletesubscription();
    }
    return () => {
      if (motionVoteDeletesubscription) motionVoteDeletesubscription.unsubscribe();
    };
  }, [votecastAgendaUid, customerId, memberJoinStatus]);

  const processVotes = (stateRef, setVotesChangedAction, setCurrentVoteAction, voting_configuration_name) => {
    if (stateRef.current !== null) {
      dispatch(setVotesChangedAction(
        stateRef.current?.votes.map(vote => ({
          ...vote,
          voting_configuration_name: voting_configuration_name,
        }))
      ));
      const currentUserVote = stateRef.current?.votes.find(vote => vote.member_uid === memberDetails.memberId);
      dispatch(setCurrentVoteAction(
        { ...currentUserVote, voting_configuration_name: voting_configuration_name }
      ))
    }
  };

  // Appsync graphql subscription- create update agenda item for vote
  useEffect(() => {
    let vote_subscription;
    const startSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        vote_subscription = await GraphQLAPI.graphql(graphqlOperation(onCreateUpdateAgendaItemForVote,
          { customer_uid: customerId, agenda_uid: votecastAgendaUid, type: AgendaItemType.Vote }, authToken
        )).subscribe({
          next: async (response) => {
            const voteAgendaItemData = response.value.data.onVotecastCreateUpdateAgendaItemForVote;
            if (voteAgendaItemData) {
              const motionUid = JSON.parse(voteAgendaItemData.metadata);
              if ((voteStateRef?.current || voteInProgressStateRef?.current) && ([voteStateRef?.current?.voteId, voteInProgressStateRef?.current?.voteId].includes(voteAgendaItemData.uid))) {
                if (voteAgendaItemData.bulk_action === VotecastBulkAction.CLERK_MARK_VOTES_ALL_YES) {
                  processVotes(voteStateRef, setVotesChanged, setCurrentVote, VoteActionEnum.Yes);
                  processVotes(voteInProgressStateRef, setVotesInProgressChanged, setCurrentVoteInProgress, VoteActionEnum.Yes);
                }
                else if ([VotecastBulkAction.ATTENDEE_CHAIR_RESET_VOTES, VotecastBulkAction.CLERK_RESET_VOTES].includes(voteAgendaItemData.bulk_action)) {
                  processVotes(voteStateRef, setVotesChanged, setCurrentVote, '');
                  processVotes(voteInProgressStateRef, setVotesInProgressChanged, setCurrentVoteInProgress, '');
                }
                else if (voteAgendaItemData.entrytime) {
                  dispatch(setIsVotingStarted(false));
                  dispatch(setVoteStop());
                  dispatch(setVoteInProgressStop());

                  //To show voting result modal
                  if (motionUid?.motion_id) {
                    const motionData = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: motionUid?.motion_id }, authParameter);
                    if (motionData && (motionData.motion_result_name === 'Passed' || motionData.motion_result_name === 'Failed')) {
                      setVotingResult(motionData.motion_result_name);
                      setShowVotingResult(true);
                    }
                  }
                  // Get all motions for active agenda item
                  const motionsForActiveAgendaItem = await invokeGraphqlOperation(getVotecastAgendaItemsByParentUid,
                    { parent_uid: currentActiveItemRef.current?.currentItemHypatiaId, type: AgendaItemType.Motion }, authParameter);
                  const latestRecordedMotionItem = motionsForActiveAgendaItem
                    .filter(motion => motion.entrytime)
                    .sort((a, b) => b.position - a.position)[0];

                  let latestRecordedMotionDetail = null;
                  if (latestRecordedMotionItem) {
                    const motionUid = JSON.parse(latestRecordedMotionItem.metadata);
                    if (motionUid?.motion_id) {
                      latestRecordedMotionDetail = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: motionUid.motion_id }, authParameter);
                    }
                  }
                  // console.log('latestRecordedMotionDetail on vote item record', latestRecordedMotionDetail);
                  if (latestRecordedMotionDetail === null || latestRecordedMotionDetail === undefined) {
                    dispatch(setCanChairStartVote(false));
                  } else {
                    if (!(latestRecordedMotionDetail.seconder_name === 'Failed for lack of second' || latestRecordedMotionDetail.seconder_name == "")) {
                      if (
                        latestRecordedMotionDetail?.voting_status === null ||
                        latestRecordedMotionDetail?.voting_status === "N/A" ||
                        latestRecordedMotionDetail?.voting_status === ""
                      ) {
                        dispatch(setCanChairStartVote(true));
                      } else if (latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.COMPLETED) {
                        dispatch(setCanChairStartVote(false));
                      }
                    } else {
                      dispatch(setCanChairStartVote(false));
                    }
                  }
                }
              }
              else if (voteAgendaItemData.bulk_action === VotecastBulkAction.CLERK_VOTE_BULK_CREATE) {
                const activeItemMetaData = JSON.parse(currentActiveItemRef.current?.metadata);
                if (motionUid?.motion_id === motionDetailsRef.current?.previousMotion?.motionId) {
                  dispatch(setVoteStarted({
                    voteId: voteAgendaItemData.uid,
                    itemText: currentActiveItemRef.current?.currentItemName,
                    motionTo: motionDetailsRef.current?.previousMotion.motionTo,
                    motionMover: motionDetailsRef.current?.previousMotion.mover ?? "",
                    motionSeconder: motionDetailsRef.current?.previousMotion.seconder ?? "",
                    motionText: motionDetailsRef.current?.previousMotion.motionText ?? "",
                    motionAction: activeItemMetaData?.suggested_action ?? "",
                  }));
                } else {
                  dispatch(setVoteStarted({
                    voteId: voteAgendaItemData.uid,
                    itemText: currentActiveItemRef.current?.currentItemName,
                    motionAction: activeItemMetaData?.suggested_action ?? "",
                  }));
                }

                const voteItemMetadata = JSON.parse(voteAgendaItemData?.metadata ?? "{}");
                const motionData = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: voteItemMetadata?.motion_id }, authParameter);
                if (motionData) {
                  dispatch(setVoteStarted({
                    motionId: motionData.uid,
                    motionText: motionData?.text ?? "",
                    motionTo: motionData?.motion_action_name ?? "",
                    motionMover: motionData?.motioner_name ?? "",
                    motionSeconder: motionData?.seconder_name ?? ""
                  }));
                  dispatch(setIsVotingStarted(true));
                }
                const activeAgendaItemData = await invokeGraphqlOperation(getActiveAgendaItem, { agenda_uid: votecastAgendaUid }, authParameter);
                if (activeAgendaItemData) {
                  const metadata = JSON.parse(activeAgendaItemData?.metadata ?? "{}");
                  dispatch(setVoteStarted({
                    itemUid: activeAgendaItemData?.uid,
                    itemText: activeAgendaItemData?.title,
                    motionAction: metadata?.suggested_action ?? "",
                  }));
                }
              }
            }
          },
          error: (error) => {
            console.error('Subscription: onCreateUpdateAgendaItemForVote error:', error);
            handleSubscriptionError(error);
          }
        });
      } catch (error) {
        console.error('Error initializing onCreateUpdateAgendaItemForVote:', error);
        handleSubscriptionError(error);
      }
    };
    if (customerId && votecastAgendaUid && memberDetails.memberId && memberJoinStatus) {
      startSubscription();
    }
    return () => {
      if (vote_subscription) vote_subscription.unsubscribe();
    };
  }, [customerId, votecastAgendaUid, memberDetails?.memberId, memberJoinStatus]);

  // This subscription is added for handling suggested_action updates
  useEffect(() => {
    let suggestedActionSubscription;
    const startSuggestedActionSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        suggestedActionSubscription = GraphQLAPI.graphql(
          graphqlOperation(onUpdateVotecastAgendaItemMetadata, {
            customer_uid: customerId,
            agenda_uid: votecastAgendaUid,
            type: AgendaItemType.Agenda,
          }, authToken)
        ).subscribe({
          next: ({ value }) => {
            const response = value?.data?.onUpdateVotecastAgendaItemMetadata;

            const item_uid = response?.uid;
            const type = response?.type;
            const metadata = response?.metadata;
            const mema_metadata_id = response?.mema_metadata_id;

            if (metadata && type === AgendaItemType.Agenda) {
              dispatch(updateAgendaItemMetadata({ mema_metadata_id, metadata }));

              if (voteStateRef.current && item_uid === voteStateRef.current.itemUid) {
                dispatch(updateVoteMotionAction({
                  motionAction: JSON.parse(metadata)?.suggested_action ?? ""
                }));
              }

              if (voteInProgressStateRef.current && item_uid === voteInProgressStateRef.current.itemUid) {
                dispatch(setVoteInProgressState({
                  ...voteInProgressStateRef.current,
                  motionAction: JSON.parse(metadata)?.suggested_action ?? ""
                }));
              }
            }
          },
          error: (error) => {
            console.error('Subscription: onUpdateVotecastAgendaItemMetadata error:', error);
            handleSubscriptionError(error);
          }
        });
      } catch (error) {
        console.error('Error initializing onUpdateVotecastAgendaItemMetadata:', error);
        handleSubscriptionError(error);
      }
    };
    if (isCloudVotingEnabled && customerId && votecastAgendaUid && memberJoinStatus) {
      startSuggestedActionSubscription();
    }
    return () => {
      if (suggestedActionSubscription) suggestedActionSubscription.unsubscribe();
    };
  }, [isCloudVotingEnabled, customerId, votecastAgendaUid, memberJoinStatus]);

  // Appsync graphql subscription- record agenda item
  useEffect(() => {
    let recordAgendaSubscription;
    const startAgendaItemRecordSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        recordAgendaSubscription = await GraphQLAPI.graphql(graphqlOperation(onAgendaItemRecord,
          { customer_uid: customerId, agenda_uid: votecastAgendaUid, status: AgendaItemActiveStatus.ACTIVE }, authToken
        )).subscribe({
          next: async (response) => {
            const agendaItemData = response.value.data.onVotecastAgendaItemRecord;
            if (agendaItemData && agendaItemData.mema_metadata_id) {
              const agendaItemMetaData = JSON.parse(agendaItemData?.metadata ?? "{}");
              const previousActiveItemId = currentActiveItemRef.current?.currentItemHypatiaId;

              // updating the current active item in redux
              dispatch(setCurrentActiveItem({
                currentItemGuid: agendaItemData.mema_metadata_id,
                currentItemName: agendaItemData.title,
                currentItemHypatiaId: agendaItemData.uid,
                metadata: agendaItemData.metadata
              }));

              // Get all motions for active agenda item
              const motionsForActiveAgendaItem = await invokeGraphqlOperation(getVotecastAgendaItemsByParentUid,
                { parent_uid: agendaItemData.uid, type: AgendaItemType.Motion }, authParameter);

              if (previousActiveItemId !== agendaItemData.uid) {
                const inCompleteMotionItem = motionsForActiveAgendaItem.find(item => !item.entrytime);
                if (inCompleteMotionItem && inCompleteMotionItem.uid) {
                  const inCompleteMotionData = await invokeGraphqlOperation(getMotionByAgendaItemId,
                    { agenda_uid: votecastAgendaUid, parent_agenda_item_uid: inCompleteMotionItem.uid }, authParameter);
                  if (inCompleteMotionData && inCompleteMotionData.length > 0) {
                    const motionInProgress = inCompleteMotionData[0];
                    if (motionInProgress) {
                      dispatch(setMotionDetails({
                        isMotionEnabled: true,
                        motionId: motionInProgress.uid,
                        position: motionInProgress.position,
                        motionTo: motionInProgress.motion_action_name,
                        motionMover: motionInProgress.motioner_name,
                        motionSeconder: motionInProgress.seconder_name
                      }));
                    }
                  }
                } else {
                  dispatch(setMotionDetails({
                    allowMotions: true,
                    isMotionEnabled: false,
                    motionId: '',
                    motionItemId: '',
                    position: '',
                    motionTo: '',
                    motionMover: '',
                    motionSeconder: '',
                    previousMotion: undefined
                  }));
                }
                dispatch(setIsVotingStarted(false));
                dispatch(setVoteStop());
                dispatch(setVoteInProgressStop());
              }

              const latestRecordedMotionItem = motionsForActiveAgendaItem
                .filter(motion => motion.entrytime)
                .sort((a, b) => b.position - a.position)[0];
              if (latestRecordedMotionItem) {
                const motionUid = JSON.parse(latestRecordedMotionItem.metadata);
                if (motionUid?.motion_id) {
                  const latestRecordedMotionDetail = await invokeGraphqlOperation(getVotecastMotionById,
                    { agenda_uid: votecastAgendaUid, uid: motionUid.motion_id }, authParameter);
                  dispatch(setMotionDetails({
                    previousMotion: latestRecordedMotionDetail !== null ? {
                      motionTo: latestRecordedMotionDetail?.motion_action_name,
                      mover: latestRecordedMotionDetail?.motioner_name,
                      seconder: latestRecordedMotionDetail?.seconder_name,
                      motionId: latestRecordedMotionDetail?.uid,
                      motionText: latestRecordedMotionDetail?.text
                    } : undefined
                  }));
                  const votesForActiveAgendaItem = await invokeGraphqlOperation(getVotecastAgendaItemsByParentUid,
                    { parent_uid: agendaItemData.uid, type: AgendaItemType.Vote }, authParameter);
                  const incompleteVoteAgendaItemData = votesForActiveAgendaItem.find(vote => !vote.entrytime);
                  if (incompleteVoteAgendaItemData) {
                    const metadata = JSON.parse(incompleteVoteAgendaItemData?.metadata ?? "{}");
                    const voteMotionData = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: metadata?.motion_id }, authParameter);
                    if (voteMotionData && voteMotionData.voting_status === VotecastVotingStatus.STARTED) {
                      dispatch(setVoteStarted({
                        itemUid: agendaItemData?.uid,
                        itemText: agendaItemData?.title,
                        voteId: incompleteVoteAgendaItemData.uid,
                        motionId: voteMotionData.uid,
                        motionText: voteMotionData?.text ?? "",
                        motionAction: agendaItemMetaData?.suggested_action ?? "",
                        motionTo: voteMotionData?.motion_action_name ?? "",
                        motionMover: voteMotionData?.motioner_name ?? "",
                        motionSeconder: voteMotionData?.seconder_name ?? ""
                      }));
                      dispatch(setIsVotingStarted(true));
                      const ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: incompleteVoteAgendaItemData.uid }, authParameter);
                      if (ballotsData && ballotsData.length > 0) {
                        const currentUserVote = ballotsData.find(vote => vote.member_uid === currentUserRef.current.memberId);
                        dispatch(setVoteStarted({ ballotId: currentUserVote?.uid }));
                        dispatch(setVotesChanged(ballotsData));
                        if (currentUserVote) {
                          dispatch(setCurrentVote(currentUserVote));
                        }
                      }
                      dispatch(setCanChairStartVote(true));
                    } else if (voteMotionData && voteMotionData.voting_status === VotecastVotingStatus.STOPPED) {
                      dispatch(setIsVotingStarted(true));
                      dispatch(setVoteStop());
                      let ballotsData = null;
                      let currentUserVote = null;
                      ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: incompleteVoteAgendaItemData.uid }, authParameter);
                      if (ballotsData && ballotsData.length > 0) {
                        currentUserVote = ballotsData.find(vote => vote.member_uid === currentUserRef.current.memberId);
                      }
                      if (ballotsData !== null && currentUserVote !== null) {
                        let payload = {
                          ballotId: currentUserVote?.uid,
                          itemUid: agendaItemData?.uid,
                          itemText: agendaItemData?.title,
                          voteId: incompleteVoteAgendaItemData.uid,
                          motionId: voteMotionData.uid,
                          motionText: voteMotionData?.text ?? "",
                          motionAction: agendaItemMetaData?.suggested_action ?? "",
                          motionTo: voteMotionData?.motion_action_name ?? "",
                          motionMover: voteMotionData?.motioner_name ?? "",
                          motionSeconder: voteMotionData?.seconder_name ?? "",
                          votes: ballotsData,
                          currentVote: currentUserVote
                        };
                        dispatch(setVoteInProgressState(payload));
                      }
                      dispatch(setCanChairStartVote(true));
                    }
                  } else {
                    if (latestRecordedMotionDetail === null || latestRecordedMotionDetail === undefined) {
                      dispatch(setCanChairStartVote(false));
                    } else {
                      if (!(latestRecordedMotionDetail.seconder_name === 'Failed for lack of second' || latestRecordedMotionDetail.seconder_name == "")) {
                        if (
                          latestRecordedMotionDetail?.voting_status === null ||
                          latestRecordedMotionDetail?.voting_status === "N/A" ||
                          latestRecordedMotionDetail?.voting_status === ""
                        ) {
                          dispatch(setCanChairStartVote(true));
                        } else if (latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.COMPLETED) {
                          dispatch(setCanChairStartVote(false));
                        }
                        // else if(
                        //   latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.STARTED || 
                        //   latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.STOPPED
                        // ){
                        //   dispatch(setCanChairStartVote(true));
                        //   // Get data for vote to resume and set in voteInProgressState
                        // }
                      } else {
                        dispatch(setCanChairStartVote(false));
                      }
                    }
                  }
                }
              } else {
                dispatch(setCanChairStartVote(false));
              }
            }
          },
          error: (error) => {
            console.error('Subscription: recordAgendaSubscription error:', error);
            handleSubscriptionError(error);
          }
        });
      } catch (error) {
        console.error('Error initializing recordAgendaSubscription:', error);
        handleSubscriptionError(error);
      }
    };
    if (isCloudVotingEnabled && customerId && votecastAgendaUid && memberJoinStatus) {
      startAgendaItemRecordSubscription();
    }
    return () => {
      if (recordAgendaSubscription) {
        recordAgendaSubscription.unsubscribe();
      }
    };
  }, [isCloudVotingEnabled, customerId, votecastAgendaUid, memberJoinStatus]);

  const toastConfig = {
    className: "live-meeting-toast",
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    closeButton: false,
    onClose: () => window.location.reload(), // Redirect on toast close
    style: { width: '475px' }
  };

  const getToastConfig = (overrides = {}) => ({
    ...toastConfig,
    ...overrides
  });

  const motionToastConfig = {
    ...toastConfig,
    onClose: undefined,
    style: undefined
  };

  useEffect(() => {
    let meetingStatus_subscription;

    const startMeetingStatusSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        meetingStatus_subscription = GraphQLAPI.graphql(
          graphqlOperation(OnUpdateVotecastAgendaMeetingStatus, { uid: votecastAgendaUid, customer_uid: customerId }, authToken)
        ).subscribe({
          next: ({ value }) => {
            const votecastMeetingData = value?.data?.onUpdateVotecastAgendaMeetingStatus;

            if (votecastMeetingData?.meeting_status) {
              dispatch(setLiveMeetingStatus(votecastMeetingData.meeting_status));
            }
          },
          error: (error) => {
            console.error('Subscription: meetingStatus_subscription error:', error);
            handleSubscriptionError(error);
          }
        });
      } catch (error) {
        console.error('Error initializing meetingStatus_subscription:', error);
        handleSubscriptionError(error);
      }
    };
    if (isCloudVotingEnabled && customerId && votecastAgendaUid && memberJoinStatus) {
      startMeetingStatusSubscription();
    }

    return () => {
      if (meetingStatus_subscription) {
        meetingStatus_subscription.unsubscribe();
      }
    };
  }, [isCloudVotingEnabled, customerId, votecastAgendaUid, memberJoinStatus]);

  // Appsync graphql subscription- stop meeting
  useEffect(() => {
    let stopMeeting_subscription;
    const startStopMeetingSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        stopMeeting_subscription = GraphQLAPI.graphql(graphqlOperation(onMeetingEndUpdate, { uid: votecastAgendaUid }, authToken
        )).subscribe({
          next: (response) => {
            const votecastMeetingData = response.value.data.onVotecastStopMeeting;
            if (votecastMeetingData && votecastMeetingData.uid === votecastAgendaUid && memberJoinStatus) {
              toast("Clerk has ended the meeting. The agenda is no longer live.", toastConfig);
            }
          },
          error: (error) => {
            console.error('Subscription onMeetingEndUpdate error:', error);
            handleSubscriptionError(error);
          }
        });
      } catch (error) {
        console.error('Error initializing onMeetingEndUpdate:', error);
        handleSubscriptionError(error);
      }
    };
    if (isCloudVotingEnabled && votecastAgendaUid && memberJoinStatus) {
      startStopMeetingSubscription();
    }
    return () => {
      if (stopMeeting_subscription) stopMeeting_subscription.unsubscribe();
    };
  }, [isCloudVotingEnabled, memberJoinStatus, votecastAgendaUid]);

  // Appsync graphql subscription- delete attendee
  useEffect(() => {
    let deleteAttendee_subscription;
    const startDeleteAttendeeSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        deleteAttendee_subscription = GraphQLAPI.graphql(
          graphqlOperation(onVotecastDeleteAttendee, { uid: memberDetails.memberId, customer_uid: customerId }, authToken
          )).subscribe({
            next: (response) => {
              const votecastDeletedMemberData = response.value.data.onVotecastDeleteMember;
              if (votecastDeletedMemberData && votecastDeletedMemberData.uid === memberDetails.memberId) {
                toast("You have been removed from the meeting. This agenda is no longer live for you.", toastConfig);
              }
            },
            error: (error) => {
              console.error('Subscription: deleteAttendee_subscription error:', error);
              handleSubscriptionError(error);
            }
          });
      } catch (error) {
        console.error('Error initializing deleteAttendee_subscription:', error);
        handleSubscriptionError(error);
      }
    }
    if (isCloudVotingEnabled && customerId && memberDetails.memberId && memberJoinStatus) {
      startDeleteAttendeeSubscription();
    }
    return () => {
      if (deleteAttendee_subscription) deleteAttendee_subscription.unsubscribe();
    };
  }, [isCloudVotingEnabled, memberJoinStatus, memberDetails.memberId]);

  // Appsync graphql subscription- member update
  useEffect(() => {
    let memberUpdateSubscription;
    const startMemberUpdateSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        memberUpdateSubscription = GraphQLAPI.graphql(
          graphqlOperation(onVotecastMemberUpdate, { uid: memberDetails.memberId, customer_uid: customerId, agenda_uid: votecastAgendaUid }, authToken
          )).subscribe({
            next: (response) => {
              const responseMemberDetails = response?.value?.data?.onVotecastMemberUpdate;
              const isAttendeePresent = responseMemberDetails?.status?.toLowerCase() === MemberAttendance.PRESENT ? true : false;
              if (isCloudVotingEnabled && currentUserRef.current?.memberJoinStatus && currentUserRef.current?.isChair
                && !responseMemberDetails?.chair && (currentUserRef.current?.isAttendeePresent == isAttendeePresent)) {
                toast(
                  "The chair for this meeting has been reset. Your permissions have been updated.",
                  getToastConfig({
                    style: { width: '630px' },
                    onClose: undefined
                  })
                );
              }
              dispatch(setMemberDetails({
                isAttendeePresent: (responseMemberDetails?.status && responseMemberDetails?.status?.toLowerCase() === MemberAttendance.PRESENT),
                isVoter: responseMemberDetails?.voting,
                isChair: responseMemberDetails?.chair
              }));
            },
            error: (error) => {
              console.error('Subscription: memberUpdateSubscription error:', error);
              handleSubscriptionError(error);
            }
          });
      } catch (error) {
        console.error('Error initializing memberUpdateSubscription:', error);
        handleSubscriptionError(error);
      }
    };
    if (isCloudVotingEnabled && customerId && votecastAgendaUid && memberJoinStatus && memberDetails.memberId) {
      startMemberUpdateSubscription();
    }
    return () => {
      if (memberUpdateSubscription) memberUpdateSubscription.unsubscribe();
    }
  }, [isCloudVotingEnabled, customerId, votecastAgendaUid, memberJoinStatus, memberDetails.memberId]);

  // Appsync graphql subscription- votes/ballot update
  useEffect(() => {
    let vote_subscription;
    const startVoteSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        vote_subscription = GraphQLAPI.graphql(
          graphqlOperation(onBallotUpdate, { customer_uid: customerId, agenda_uid: votecastAgendaUid }, authToken
          )).subscribe({
            next: (response) => {
              const updateVoteData = response.value.data.onVotecastUpdateBallot;
              if (updateVoteData && updateVoteData.request_origin === VotecastRequestOrigin.CLERK_CREATE) {
                dispatch(setCurrentVote(updateVoteData));

                if (updateVoteData.member_uid === memberDetails.memberId) {
                  dispatch(setVoteStarted({ ballotId: updateVoteData.uid }));
                }
              }
              else if (updateVoteData && [VotecastRequestOrigin.CLERK_UPDATE, VotecastRequestOrigin.ATTENDEE_UPDATE].includes(updateVoteData.request_origin)
                && (updateVoteData.parent_agenda_item_uid === voteStateRef.current?.voteId || updateVoteData.parent_agenda_item_uid === voteInProgressStateRef.current?.voteId)) {
                const voter = {
                  uid: updateVoteData.uid,
                  motion_uid: updateVoteData.motion_uid,
                  member_name: updateVoteData.member_name,
                  member_uid: updateVoteData.member_uid,
                  voting_configuration_name: updateVoteData.voting_configuration_name
                };
                if (voteStateRef.current !== null) {
                  dispatch(setCurrentVote(voter));
                } else if (voteInProgressStateRef.current !== null) {
                  dispatch(setCurrentVoteInProgress(voter));
                }
              }
            },
            error: (error) => {
              console.error('Subscription onBallotUpdate error:', error);
              handleSubscriptionError(error);
            }
          });
      } catch (error) {
        console.error('Error initializing onBallotUpdate:', error);
        handleSubscriptionError(error);
      }
    }
    if (isCloudVotingEnabled && customerId && votecastAgendaUid && memberJoinStatus) {
      startVoteSubscription();
    }
    return () => {
      if (vote_subscription) vote_subscription.unsubscribe();
    };
  }, [customerId, votecastAgendaUid, memberJoinStatus]);

  const renderTreeTitle = (node, selectedItemKey) => (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {node.title}
      {isCloudVotingEnabled && memberJoinStatus &&
        node.key === selectedItemKey && node.key === currentActiveItemRef.current?.currentItemGuid ? (
        <span style={{ marginLeft: '3px', fontSize: '0.9em', display: 'flex', alignItems: 'center', color: 'white' }}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="sm"
            style={{ color: 'white', marginRight: '4px' }}
          />
          Active
        </span>
      ) : isCloudVotingEnabled && memberJoinStatus && node.key === currentActiveItemRef.current?.currentItemGuid ? (
        <span style={{ marginLeft: '3px', fontSize: '0.9em', display: 'flex', alignItems: 'center', color: 'green' }}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="sm"
            style={{ color: 'green', marginRight: '4px' }}
          />
          Active
        </span>
      ) : null
      }
    </span>
  );

  const renderVotingResultModal = () => {
    return (
      <div>

        {/* Voting Result Modal */}
        <VotingResultModal
          isOpen={showVotingResult}
          onClose={handleClose}
          votingResult={votingResult}
        />
      </div>
    );
  }

  return (
    <div id="agenda-items-container">
      {isCloudVotingEnabled && isVotingStarted && <VotingModal />}
      {renderVotingResultModal()}
      {props?.itemsTreeData.length > 0 ?
        (<Tree
          className="agenda-items"
          defaultExpandAll={true}
          selectedKeys={[selectedItemKey]}
          expandedKeys={itemKeys}
          treeData={props.itemsTreeData}
          showIcon={false}
          onSelect={(_, e) => showItemDetail(e.node.key)}
          switcherIcon={null}
          titleRender={(node) => renderTreeTitle(node, selectedItemKey)}
        />) :
        (<p className="active-agenda-empty-message" data-testid="empty_agenda_message">
          Items were not found for this meeting agenda
        </p>)
      }

    </div>
  );
};

AgendaItems.displayName = 'AgendaItems';

AgendaItems.propTypes = {
  itemsTreeData: PropTypes.array.isRequired,
};

export default AgendaItems;
