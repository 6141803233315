import { createSlice } from '@reduxjs/toolkit';
 
const initialState = {
  liveMeetingStatus : '',
  isMeetingInSession: false,
  isMeetingRunning: false,
  attendees: [],
  isCloudVotingEnabled: false,
  isCloudVotingEnabledForClient: false,
  isCloudVotingEnabledForEvent: false,
  showLiveVotingForEvent: false,
  customerId: '',
  agendaId: '',
  currentScreen: '',
  activeAgendaItemTitle: '',
  calledSpeaker: { 
    agenda_uid: '',
    customer_uid: '',
    duration: '',
    item_uid: '',
    name: '',
    position: '',
    speaker_type: '',
    start_time: '',
    status: '',
    uid: '',
  },
  voters:[],
  motion_result_name :'',
  voteState : undefined,
  currrentMotionId: '',
  meetingStatusTimestamp: '',
  agendaTitle: '',
  motionDetails: {
    motionId: '',
    motionItemId: '',
    motionTo: '',
    motionMover: '',
    motionSeconder: '',
    motionActionName: ''
  },
  previousMeetingStatus: '',
  voteInitiated: false,
};
 
const vcdLiveMeeting = createSlice({
  name: 'vcdLiveMeeting',
  initialState,
  reducers: {
    setLiveMeetingStatus: (state, { payload }) => {
      state.previousMeetingStatus = state.liveMeetingStatus;
      state.liveMeetingStatus = payload;
    },
    setIsMeetingInSession: (state, { payload }) => {
      state.isMeetingInSession = payload;
    },
    setIsMeetingRunning: (state, { payload }) => {
      state.isMeetingRunning = payload;
    },
    setAttendees: (state, { payload }) => {
      state.attendees = payload;
    },
    setIsCloudVotingEnabled: (state, { payload }) => {
      state.isCloudVotingEnabled = payload;
    },
    setSettingValuesForEvent: (state, { payload }) => {
      state.isCloudVotingEnabledForEvent = payload.isCloudVotingEnabledForEvent;
      state.showLiveVotingForEvent = payload.showLiveVotingForEvent;
    },
    setIsCloudVotingEnabledForClient: (state, { payload }) => {
      state.isCloudVotingEnabledForClient = payload;
    },
    setCustomerId: (state, { payload }) => {
      state.customerId = payload;
    },
    setAgendaId: (state, { payload }) => {
      state.agendaId = payload;
    },
    setCurrentScreen: (state, { payload }) => {
      state.currentScreen = payload;
    },
    setActiveAgendaItemTitle : (state,{payload})=>{
      state.activeAgendaItemTitle = payload
    },
    setCalledSpeaker: (state, { payload }) => {
      state.calledSpeaker = payload;
    },
    setVoters:(state, {payload}) =>{
      if (payload?.length === 0) {
        state.voters = [];
      } else {
        const index = state.voters.findIndex(v => v.uid === payload.uid);
        if (index !== -1) {
          state.voters[index] = payload;
        } else {
          state.voters = [...state.voters, payload];
        }
      }
    },
    setMotionResultName:(state, {payload})=>{
      state.motion_result_name = payload;
    },
    setVoteResult: (state, { payload }) => {
      const voteState = {
        motionId: payload.motionId,
        voteId: payload.voteId,
        motionText: payload.motionText,
        motionTo: payload.motionTo,
        motionMover: payload.motionMover,
        motionSeconder: payload.motionSeconder,
        motionResult : payload.motionResult
      };
      state.voteState = voteState;
    },
    setCurrentMotionId : (state, {payload})=>{
      state.currrentMotionId = payload;
    },
    setMeetingStatusTimestamp : (state,{payload})=>{
      state.meetingStatusTimestamp = payload
    },
    setAgendaTitle: (state, { payload }) => {
      state.agendaTitle = payload;
    },
    setMotionDetails: (state, { payload }) => {
      state.motionDetails = {
        ...state.motionDetails,
        ...payload,
      };
    },
    clearAttendees: (state) => {
      state.attendees = [];
    },
    setVoteInitiated:(state, {payload})=>{
      state.voteInitiated = payload;
    },
  },
});
 
export default vcdLiveMeeting.reducer;
 
export const {
    setLiveMeetingStatus,
    setIsMeetingInSession,
    setIsMeetingRunning,
    setAttendees,
    setIsCloudVotingEnabled,
    setCustomerId,
    setAgendaId,
    setCurrentScreen,
    setActiveAgendaItemTitle,
    setCalledSpeaker,
    setVoters,
    setMotionResultName,
    setVoteResult,
    setCurrentMotionId,
    setMeetingStatusTimestamp,
    setAgendaTitle,
    setMotionDetails,
    setSettingValuesForEvent,
    setIsCloudVotingEnabledForClient,
    clearAttendees,
    setVoteInitiated
} = vcdLiveMeeting.actions;

export const selectVoteState = (state) => state.vcdLiveMeeting.voteState;
export const selectLiveVotingEnable = (state) => state.vcdLiveMeeting?.isCloudVotingEnabledForClient && state.vcdLiveMeeting?.showLiveVotingForEvent;