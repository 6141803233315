import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  collapsedStreamPanel: false,
  localDistribution: {
    result: false,
    servers: []
  }
};

const limaweb = createSlice({
  name: 'limaweb',
  initialState,
  reducers: {
    setCollapsedStreamPanel: (state, action) => {
      state.collapsedStreamPanel = action.payload;
    },
    setLocalDistribution: (state, action) => {
      state.localDistribution = action.payload
    },
    setHasEncoder: (state, action) => {
      state.hasEncoder = action.payload
    },
    setMemaEventStatus: (state, action) => {
      state.memaEventStatus = action.payload;
    }
  }
});

export const {
  setCollapsedStreamPanel,
  setLocalDistribution,
  setHasEncoder,
  setMemaEventStatus
} = limaweb.actions;

export default limaweb.reducer;