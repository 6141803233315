import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

// Would be used to get settings like cloudvoting_enable
/*
const {
  data: data,
  isSuccess: isSettingSuccess,
  isError: isSettingError,
} = useGetSettingQuery('cloudvoting_enable');
*/
export const settingApi = createApi({
  reducerPath: 'settingApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getSetting: builder.query({
      query: (key) => `/api/setting/${key}`,
      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useGetSettingQuery } = settingApi;
