import React from "react";
import { useSelector } from "react-redux";
import { selectVotes } from "../../../common/liveMeetingSlice";
import { VoteActionEnum } from "../../../../constants/cloudVotingConstants";
import { selectIsShowIndividualVote } from "../../../common/settingSlice";

const yes = <span className="color-green">Yes</span>;
const abstain = <span className="color-blue">Abstain</span>;
const no = <span className="color-grenadier">No</span>;

const VotingResults = () => {
  const votes = useSelector(selectVotes) || [];
  const yesCount = votes.filter(
    (v) => v.voting_configuration_name === VoteActionEnum.Yes
  ).length;
  const noCount = votes.filter(
    (v) => v.voting_configuration_name === VoteActionEnum.No
  ).length;
  const abstainCount = votes.filter(
    (v) => v.voting_configuration_name === VoteActionEnum.Abstain
  ).length;

  return (
    <div className="voting-resuilts-container">
      <div className="voting-resuilts-summary">
        <div className="voting-header">Voting Results</div>
        <div className="voting-list">
          <div className="voting-list-item">
            <div className="sub-text">{yesCount}</div>
            <div className="voting-sub-title-green">Yes</div>
          </div>
          <div className="voting-list-item">
            <div className="sub-text">{noCount}</div>
            <div className="voting-sub-title-grenadier">No</div>
          </div>
          <div className="voting-list-item">
            <div className="sub-text">{abstainCount}</div>
            <div className="voting-sub-title-blue">Abstain</div>
          </div>
        </div>
      </div>
      <div className="voting-resuilts-individual">
        <div className="voting-header">Individual Voters</div>
        <div className="voting-list voting-invidual">
          {votes
            .filter(v => v.voting_configuration_name)
            .map((vote) => (
              <IndividualVote
                key={`${vote.uid}-${vote.member_name}`}
                name={vote.member_name}
                value={vote.voting_configuration_name}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const getVoteValueText = (value) => {
  switch (value) {
    case VoteActionEnum.Yes:
      return yes;
    case VoteActionEnum.No:
      return no;
    case VoteActionEnum.Abstain:
      return abstain;
    default:
      return null;
  }
};
const IndividualVote = ({ name, value }) => {
  const showIndividualVote = useSelector(selectIsShowIndividualVote);
  return (
    <div className="voters-list-item">
      <div className="sub-title">{name}</div>
      <div className="sub-text">
        {showIndividualVote ? getVoteValueText(value) : "Voted"}
      </div>
    </div>
  );
};

VotingResults.displayName = "VotingResults";

export default VotingResults;
