import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const eventApi = createApi({
  reducerPath: 'eventApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getEvent: builder.query({
      query: (event_uid) => `api/event/${event_uid}`,
    }),
  }),
});

export const { useGetEventQuery } = eventApi;