import { combineReducers } from 'redux';

import agenda from './agenda';
import attendees from './attendees';
import clip from './clip';
import error from './error';
import limaweb from './limaweb';
import motions from './motions';
import votes from './votes';
import settings from './settings';
import liveMeetingLima from './liveMeetingLima';

export default combineReducers({
  agenda,
  attendees,
  clip,
  error,
  limaweb,
  motions,
  votes,
  settings,
  liveMeetingLima
});